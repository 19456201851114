import { CmsImageCrops, PictureProps } from '@components/Cms/CmsComponents-CSS/CmsMedia/Picture'
import { CtaBG, CtaFill, CtaStyle } from '../types/common'

import { Theme } from '@mui/material'

export const APP_MAIN_CONTAINER_CLASSNAME = `${process.env.NEXT_PUBLIC_STORENAME}-app__main-container`
export const APP_MAIN_CONTAINER_ID = `${process.env.NEXT_PUBLIC_STORENAME}-app__main`
export const MODAL_INFO_ARTICLE_CROP_TYPE = 'MODAL_INFO_ARTICLE'

export const ctaStylesMap: CtaStyle = {
  primary: 'primary',
  secondary: 'secondary',
}
export const ctaFillMap: CtaFill = {
  fill: 'fill',
  outline: 'outline',
}

export const ctaBgMap: CtaBG = {
  light: 'light',
  dark: 'dark',
  darkVariant: 'darkVariant',
  lightVariant: 'lightVariant',
}
export const cmsImageCrops: CmsImageCrops = {
  FULL_WIDTH_BANNER_DUAL: {
    deskL: {
      crop: 'landscape_ratio48x35',
      height: 1120,
      width: 1536,
    },
    deskS: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    tabletL: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    tabletP: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    mobile: {
      crop: 'landscape_ratio35x18',
      width: 572,
      height: 294,
    },
  },
  BOX_WITH_MARGIN_BANNER: {
    deskL: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio17x18',
      width: 343,
      height: 363,
    },
  },
  MODAL_INFO_ARTICLE: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'landscape_ratio33x18',
      width: 764,
      height: 417,
    },
    mobile: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
  },
  FULL_WIDTH_BANNER_L: {
    xxl: {
      crop: 'landscape_ratio2x1',
      width: 2048,
      height: 1024,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 1024,
        },
        {
          density: '2x',
          width: 3840,
          height: 1920,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio2x1',
      width: 1536,
      height: 768,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 768,
        },
        {
          density: '2x',
          width: 2880,
          height: 1440,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2560,
          height: 1120,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
  },
  LANDSCAPE_BANNER_M: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2560,
          height: 1260,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
    deskL: {
      crop: 'landscape_ratio32x13',
      width: 1280,
      height: 504,
    },
    deskS: {
      crop: 'landscape_ratio32x13',
      width: 1280,
      height: 504,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
  },
  TOP_PAGE_BANNER_S: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2592,
          height: 1134,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio35x18',
      width: 768,
      height: 395,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 395,
        },
        {
          density: '2x',
          width: 1536,
          height: 790,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1280,
          height: 658,
        },
      ],
    },
  },
  SQUAT_BANNER_XS: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2592,
          height: 1134,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio35x18',
      width: 768,
      height: 395,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 395,
        },
        {
          density: '2x',
          width: 1536,
          height: 790,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1280,
          height: 658,
        },
      ],
    },
  },
  FULL_WIDTH_BANNER_PROMO_L: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
  },
  LANDSCAPE_BANNER_PROMO_M: {
    xxl: {
      crop: 'landscape_ratio16x7',
      width: 2048,
      height: 896,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 896,
        },
        {
          density: '2x',
          width: 3840,
          height: 1680,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio16x7',
      width: 1536,
      height: 672,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 672,
        },
        {
          density: '2x',
          width: 2880,
          height: 1260,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio2x1',
      width: 1280,
      height: 640,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 640,
        },
        {
          density: '2x',
          width: 2592,
          height: 1280,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
  },
  TOP_PAGE_BANNER_PROMO_S: {
    deskL: {
      crop: 'landscape_ratio16x7',
      width: 1980,
      height: 866,
    },
    deskS: {
      crop: 'landscape_ratio16x7',
      width: 2592,
      height: 1134,
    },
    tabletL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletP: {
      crop: 'landscape_ratio16x5',
      width: 1536,
      height: 480,
    },
    mobile: {
      crop: 'landscape_ratio16x5',
      width: 768,
      height: 240,
    },
  },
  G1_W_2_ITEMS: {
    xxl: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio33x18',
      width: 768,
      height: 419,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 419,
        },
        {
          density: '2x',
          width: 1536,
          height: 838,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 768,
      height: 419,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 419,
        },
        {
          density: '2x',
          width: 1536,
          height: 838,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1280,
          height: 698,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1280,
          height: 698,
        },
      ],
    },
  },
  G1_W_3_ITEMS: {
    xxl: {
      crop: 'landscape_ratio48x35',
      width: 768,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 560,
        },
        {
          density: '2x',
          width: 1536,
          height: 1120,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio48x35',
      width: 576,
      height: 420,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 420,
        },
        {
          density: '2x',
          width: 1024,
          height: 747,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1024,
          height: 559,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1024,
          height: 559,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1024,
          height: 1189,
        },
      ],
    },
  },
  G1_W_4_ITEMS: {
    xxl: {
      crop: 'portrait_ratio11x12',
      width: 1024,
      height: 1117,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 1117,
        },
        {
          density: '2x',
          width: 2048,
          height: 2234,
        },
      ],
    },
    xl: {
      crop: 'portrait_ratio11x12',
      width: 576,
      height: 628,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 628,
        },
        {
          density: '2x',
          width: 576,
          height: 1117,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1024,
          height: 527,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1024,
          height: 527,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1024,
          height: 1189,
        },
      ],
    },
  },
  PRODUCT_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    deskS: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
    mobile: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
  },
  GRID_OF_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    deskS: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
    mobile: {
      crop: 'landscape_ratio19x18',
      width: 380,
      height: 360,
    },
  },
  G1_W_INSTA: {
    deskL: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    deskS: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    tabletL: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    tabletP: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    mobile: {
      crop: 'portrait_ratio11x12',
      width: 327,
      height: 357,
    },
  },
  G5_CAROUSEL_MIX: {
    deskL: {
      crop: 'portrait_ratio18x19',
      width: 360,
      height: 380,
    },
    deskS: {
      crop: 'portrait_ratio18x19',
      width: 360,
      height: 380,
    },
    tabletL: {
      crop: 'portrait_ratio17x20',
      width: 240,
      height: 282,
    },
    tabletP: {
      crop: 'portrait_ratio19x20',
      width: 285,
      height: 300,
    },
    mobile: {
      crop: 'portrait_ratio19x20',
      width: 285,
      height: 300,
    },
  },
  G5_BOX_ALL_FIELDS: {
    deskL: {
      crop: 'landscape_ratio25x22',
      width: 1440,
      height: 1267,
    },
    deskS: {
      crop: 'portrait_ratio17x20',
      width: 1280,
      height: 1505,
    },
    tabletL: {
      crop: 'portrait_ratio15x23',
      width: 1024,
      height: 1570,
    },
    tabletP: {
      crop: 'portrait_ratio15x23',
      width: 1024,
      height: 1570,
    },
    mobile: {
      crop: 'portrait_ratio3x4',
      width: 375,
      height: 500,
    },
  },
  BOARD_WITH_FIELDS_2_ITEMS: {
    deskL: {
      crop: 'landscape_ratio326x181',
      width: 1304,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio286x181',
      width: 1144,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio238x181',
      width: 857,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio343x362',
      width: 617,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_3_ITEMS: {
    deskL: {
      crop: 'landscape_ratio216x181',
      width: 864,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio379x362',
      width: 758,
      height: 724,
    },
    tabletL: {
      crop: 'portrait_ratio315x362',
      width: 567,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio204x181',
      width: 734,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  SQUARE_BOARDS: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLAIN_SLIDER: {
    deskL: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    deskS: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio35x18',
      width: 572,
      height: 294,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 315,
      height: 366,
    },
  },
  BOX_WITH_2_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio121x85',
      width: 968,
      height: 680,
    },
    deskS: {
      crop: 'landscape_ratio121x85',
      width: 847,
      height: 603,
    },
    tabletL: {
      crop: 'landscape_ratio121x85',
      width: 654,
      height: 602,
    },
    tabletP: {
      crop: 'landscape_ratio121x85',
      width: 704,
      height: 528,
    },
    mobile: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
  },
  BOX_WITH_4_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 675,
      height: 675,
    },
    deskS: {
      crop: 'landscape_ratio64x29',
      width: 572,
      height: 577,
    },
    tabletL: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
    tabletP: {
      crop: 'landscape_ratio755x361',
      width: 755,
      height: 361,
    },
    mobile: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
  },
  LANDSCAPE_BANNER: {
    deskL: {
      crop: 'landscape_ratio40x13',
      width: 2880,
      height: 936,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 1280,
      height: 416,
    },
    tabletL: {
      crop: 'landscape_ratio32x13',
      width: 1024,
      height: 416,
    },
    tabletP: {
      crop: 'landscape_ratio48x17',
      width: 1536,
      height: 544,
    },
    mobile: {
      crop: 'landscape_ratio25x19',
      width: 468,
      height: 356,
    },
  },
  ONE_ITEM_PRODUCT_CAROUSEL: {
    mobile: {
      crop: 'landscape_ratio16x5',
      width: 468,
      height: 146,
    },
  },
  PDP_BANNER: {
    deskL: {
      crop: 'landscape_ratio48x17',
      width: 1536,
      height: 544,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 1280,
      height: 416,
    },
    tabletL: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    tabletP: {
      crop: 'landscape_ratio16x5',
      width: 768,
      height: 240,
    },
    mobile: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
  },
  BANNER_W_SIDE_FIELDS: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
      height: 1520,
    },
    tablet: {
      crop: 'portrait_ratio23x29',
      width: 1480,
      height: 2510,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 740,
      height: 933,
    },
  },
  BANNER_W_SIDE_FIELDS_RELATED: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
      height: 1520,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  BLURRED_SLIDER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
      height: 675,
    },
    tablet: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'landscape_ratio2x1',
      width: 920,
      height: 460,
    },
  },
  NARROW_BANNER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
      height: 675,
    },
    tablet: {
      crop: 'portrait_ratio23x29 ',
      width: 1840,
      height: 2320,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 920,
      height: 1160,
    },
  },
  MOBILE_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  BOX_1: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  CART_PROMO_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  AVATAR_MEGA_MENU: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
  },
  BIG_MENU_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_ONE_TILE: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_TWO_TILES: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletP: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_THREE_TILES: {
    deskL: {
      crop: 'landscape_ratio16x7',
      width: 2880,
      height: 1260,
    },
    deskS: {
      crop: 'landscape_ratio16x7',
      width: 2880,
      height: 1260,
    },
    tabletP: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
} as const

export type ImageCropTypes = keyof typeof cmsImageCrops

export const PDP_STICKY_BAR_HEIGHT = '72px'

export const getDesktopThemeBreakpoint = (theme: Theme) => theme.breakpoints.up('md')

// DC CROP PROPERTIES
//
// landscape_ratio9x4 - 1440 - 640
// landscape_ratio9x4 - 2592 - 1152
// landscape_ratio9x4 - 2880 - 1280

// landscape_ratio16x7 - 1280 - 560
// landscape_ratio16x7 - 1980 - 866
// landscape_ratio16x7 - 2560 - 1120
// landscape_ratio16x7 - 1024 - 448
// landscape_ratio16x7 - 1440 - 630
// landscape_ratio16x7 - 2048 - 896

// landscape_ratio8x5 - 768 - 480
// landscape_ratio8x5 - 1280 - 800
// landscape_ratio8x5 - 1536 - 960

// portrait_ratio3x4 - 375 - 500
// portrait_ratio3x4 - 468 - 624
// portrait_ratio3x4 - 750 - 1000

// landscape_ratio9x2 - 1440 - 320
// landscape_ratio9x2 - 2592 - 576
// landscape_ratio9x2 - 2880 - 640
// landscape_ratio9x2 - 1280 - 284

// landscape_ratio3x1 - 1440 - 480
// landscape_ratio3x1 - 1280 - 427

// landscape_ratio16x9 - 640 - 360
// landscape_ratio16x9 - 880 - 495
// landscape_ratio16x9 - 1280 - 720
// landscape_ratio16x9 - 640 - 360
// landscape_ratio16x9 - 1280 - 720
// landscape_ratio16x9 - 1440 - 810
// landscape_ratio16x9 - 1920 - 1080

// landscape_ratio15x8 - 660 - 352
// landscape_ratio15x8 - 1024 - 546
// landscape_ratio15x8 - 1320 - 704

// landscape_ratio11x5 - 572 - 260
// landscape_ratio11x5 - 880 - 400
// landscape_ratio11x5 - 1144 - 520

// landscape_ratio15x11 - 572 - 260
// landscape_ratio15x11 - 750 - 550
// landscape_ratio15x11 - 960 - 704
// landscape_ratio15x11 - 720 - 528
// landscape_ratio15x11 - 1440 - 1056
// landscape_ratio15x11 - 666 - 488
// landscape_ratio15x11 - 1920 - 1408
// landscape_ratio15x11 - 572 - 260
// landscape_ratio15x11 - 750 - 550
// landscape_ratio15x11 - 960 - 704
// landscape_ratio15x11 - 1440 - 1056
// landscape_ratio15x11 - 1920 - 1408
// landscape_ratio15x11 - 666 - 488
// landscape_ratio15x11 - 704 - 516
// landscape_ratio15x11 - 720 - 528
// landscape_ratio15x11 - 1440 - 1056
// landscape_ratio15x11 - 666 - 488
// landscape_ratio15x11 - 1920 - 1408

// landscape_ratio2x1 - 704 - 352
// landscape_ratio2x1 - 960 - 480
// landscape_ratio2x1 - 1408 - 704
// landscape_ratio2x1 - 664 - 332
// landscape_ratio2x1 - 740 - 370
// landscape_ratio2x1 - 1328 - 664
// landscape_ratio2x1 - 1480 - 740
// landscape_ratio2x1 - 360 - 180
// landscape_ratio2x1 - 720 - 360
// landscape_ratio2x1 - 1296 - 648
// landscape_ratio2x1 - 1440 - 720
// landscape_ratio2x1 - 920 - 460
// landscape_ratio2x1 - 1656 - 828
// landscape_ratio2x1 - 1840 - 920
// landscape_ratio2x1 - 460 - 230
// landscape_ratio2x1 - 828 - 414
// landscape_ratio2x1 - 920 - 460
// landscape_ratio2x1 - 664 - 332
// landscape_ratio2x1 - 740 - 370
// landscape_ratio2x1 - 704 - 352
// landscape_ratio2x1 - 1328 - 664
// landscape_ratio2x1 - 1480 - 740
// landscape_ratio2x1 - 360 - 180
// landscape_ratio2x1 - 720 - 360
// landscape_ratio2x1 - 1296 - 648
// landscape_ratio2x1 - 1440 - 720
// landscape_ratio2x1 - 920 - 460
// landscape_ratio2x1 - 1656 - 828
// landscape_ratio2x1 - 1840 - 920
// landscape_ratio2x1 - 460 - 230
// landscape_ratio2x1 - 828 - 414
// landscape_ratio2x1 - 920 - 460

// portrait_ratio11x12 - 343 - 374
// portrait_ratio11x12 - 460 - 502
// portrait_ratio11x12 - 686 - 748
// portrait_ratio11x12 - 343 - 374
// portrait_ratio11x12 - 460 - 502
// portrait_ratio11x12 - 686 - 748

// landscape_ratio6x5 - 432 - 360
// landscape_ratio6x5 - 660 - 550
// landscape_ratio6x5 - 864 - 720
// landscape_ratio6x5 - 360 - 300
// landscape_ratio6x5 - 440 - 367
// landscape_ratio6x5 - 720 - 600

// landscape_ratio23x25 - 316 - 343
// landscape_ratio23x25 - 440 - 478
// landscape_ratio23x25 - 632 - 687

// landscape_ratio25x22 - 400 - 352
// landscape_ratio25x22 - 740 - 651
// landscape_ratio25x22 - 800 - 704
// landscape_ratio25x22 - 1440 - 1267

// landscape_ratio5x3 - 600 - 360
// landscape_ratio5x3 - 1200 - 720
// landscape_ratio5x3 - 1440 - 864
// landscape_ratio5x3 - 1920 - 1152

// landscape_ratio8x3 - 704 - 264
// landscape_ratio8x3 - 1408 - 528
// landscape_ratio8x3 - 1332 - 500
// landscape_ratio8x3 - 1920 - 720

// portrait_ratio1x1 - 343 - 343
// portrait_ratio1x1 - 720 - 720
// portrait_ratio1x1 - 1296 - 1296
// portrait_ratio1x1 - 1440 - 1440
// portrait_ratio1x1 - 370 - 370
// portrait_ratio1x1 - 666 - 666
// portrait_ratio1x1 - 740 - 740
// portrait_ratio1x1 - 740 - 740
// portrait_ratio1x1 - 1332 - 1332
// portrait_ratio1x1 - 1480 - 1480
// portrait_ratio1x1 - 720 - 720
// portrait_ratio1x1 - 1296 - 1296
// portrait_ratio1x1 - 1440 - 1440
// portrait_ratio1x1 - 370 - 370
// portrait_ratio1x1 - 666 - 666
// portrait_ratio1x1 - 740 - 740
// portrait_ratio1x1 - 740 - 740
// portrait_ratio1x1 - 1332 - 1332
// portrait_ratio1x1 - 1480 - 1480

// landscape_ratio29x15 - 580 - 300
// landscape_ratio29x15 - 1296 - 670
// landscape_ratio29x15 - 1480 - 766
// landscape_ratio29x15 - 1920 - 993
// landscape_ratio29x15 - 666 - 344

// landscape_ratio13x8 - 572 - 352
// landscape_ratio13x8 - 360 - 222
// landscape_ratio13x8 - 1296 - 798
// landscape_ratio13x8 - 1440 - 886
// landscape_ratio13x8 - 1840 - 1132
// landscape_ratio13x8 - 460 - 283
// landscape_ratio13x8 - 920 - 566

// landscape_ratio24x11 - 768 - 352
// landscape_ratio24x11 - 960 - 440
// landscape_ratio24x11 - 1332 - 611
// landscape_ratio24x11 - 1440 - 660
// landscape_ratio24x11 - 1920 - 880
// landscape_ratio24x11 - 768 - 352
// landscape_ratio24x11 - 960 - 440
// landscape_ratio24x11 - 1332 - 611
// landscape_ratio24x11 - 1440 - 660
// landscape_ratio24x11 - 1920 - 880

// landscape_ratio27x13 - 864 - 416
// landscape_ratio27x13 - 1728 - 832
// landscape_ratio27x13 - 1440 - 693
// landscape_ratio27x13 - 1920 - 924
// landscape_ratio27x13 - 1332 - 641
// landscape_ratio27x13 - 810 - 390
// landscape_ratio27x13 - 864 - 416
// landscape_ratio27x13 - 1728 - 832
// landscape_ratio27x13 - 1440 - 693
// landscape_ratio27x13 - 1920 - 924
// landscape_ratio27x13 - 1332 - 641

// portrait_ratio19x20 - 285 - 300
// portrait_ratio19x20 - 440 - 463
// portrait_ratio19x20 - 880 - 926
// portrait_ratio19x20 - 960 - 1011
// portrait_ratio19x20 - 1080 - 1137
// portrait_ratio19x20 - 1332 - 1402
// portrait_ratio19x20 - 1440 - 1516
// portrait_ratio19x20 - 1920 - 2021

// portrait_ratio18x19 - 360 - 380
// portrait_ratio18x19 - 480 - 507
// portrait_ratio18x19 - 880 - 929
// portrait_ratio18x19 - 960 - 1013
// portrait_ratio18x19 - 1080 - 1140
// portrait_ratio18x19 - 1332 - 1406
// portrait_ratio18x19 - 1440 - 1520
// portrait_ratio18x19 - 1920 - 2027

// landscape_ratio29x8 - 1160 - 320
// landscape_ratio29x8 - 800 - 221
// landscape_ratio29x8 - 960 - 265
// landscape_ratio29x8 - 1080 - 298
// landscape_ratio29x8 - 1332 - 367
// landscape_ratio29x8 - 1440 - 397
// landscape_ratio29x8 - 1920 - 530

// landscape_ratio30x8 - 1152 - 307
// landscape_ratio30x8 - 1312 - 350
// landscape_ratio30x8 - 800 - 213
// landscape_ratio30x8 - 960 - 256
// landscape_ratio30x8 - 1080 - 288
// landscape_ratio30x8 - 1332 - 355
// landscape_ratio30x8 - 1440 - 384
// landscape_ratio30x8 - 1920 - 512
// landscape_ratio30x8 - 1152 - 307
// landscape_ratio30x8 - 1312 - 350
// landscape_ratio30x8 - 800 - 213
// landscape_ratio30x8 - 960 - 256
// landscape_ratio30x8 - 1080 - 288
// landscape_ratio30x8 - 1332 - 355
// landscape_ratio30x8 - 1440 - 384
// landscape_ratio30x8 - 1920 - 512

// landscape_ratio30x7 - 960 - 224
// landscape_ratio30x7 - 800 - 187
// landscape_ratio30x7 - 1152 - 269
// landscape_ratio30x7 - 1080 - 252
// landscape_ratio30x7 - 1332 - 311
// landscape_ratio30x7 - 1440 - 336
// landscape_ratio30x7 - 1920 - 448

// landscape_ratio15x7 - 864 - 403
// landscape_ratio15x7 - 960 - 448
// landscape_ratio15x7 - 1152 - 538
// landscape_ratio15x7 - 1332 - 622
// landscape_ratio15x7 - 1440 - 672
// landscape_ratio15x7 - 1920 - 896

// portrait_ratio17x20 - 343 - 404
// portrait_ratio17x20 - 440 - 518
// portrait_ratio17x20 - 666 - 784
// portrait_ratio17x20 - 880 - 1035
// portrait_ratio17x20 - 960 - 1129
// portrait_ratio17x20 - 1332 - 1567
// portrait_ratio17x20 - 1440 - 1694
// portrait_ratio17x20 - 1920 - 2259

// landscape_ratio18x10 - 722 - 401
// landscape_ratio18x10 - 440 - 244
// landscape_ratio18x10 - 666 - 370
// landscape_ratio18x10 - 880 - 489
// landscape_ratio18x10 - 960 - 533
// landscape_ratio18x10 - 1332 - 740
// landscape_ratio18x10 - 1440 - 800
// landscape_ratio18x10 - 1920 - 1067
// landscape_ratio18x10 - 2259 - 1255

// landscape_ratio9x6 - 876 - 584
// landscape_ratio9x6 - 722 - 481
// landscape_ratio9x6 - 666 - 444
// landscape_ratio9x6 - 960 - 640
// landscape_ratio9x6 - 1332 - 888
// landscape_ratio9x6 - 1440 - 960
// landscape_ratio9x6 - 1920 - 1280
// landscape_ratio9x6 - 2880 - 1920

// landscape_ratio10x7 - 968 - 678
// landscape_ratio10x7 - 666 - 466
// landscape_ratio10x7 - 880 - 616
// landscape_ratio10x7 - 960 - 672
// landscape_ratio10x7 - 1332 - 932
// landscape_ratio10x7 - 1440 - 1008
// landscape_ratio10x7 - 1920 - 1344
// landscape_ratio10x7 - 2880 - 2016

// landscape_ratio7x5 - 846 - 604
// landscape_ratio7x5 - 666 - 476
// landscape_ratio7x5 - 960 - 686
// landscape_ratio7x5 - 1080 - 771
// landscape_ratio7x5 - 1332 - 951
// landscape_ratio7x5 - 1440 - 1029
// landscape_ratio7x5 - 1920 - 1371

// landscape_ratio13x10 - 655 - 504
// landscape_ratio13x10 - 960 - 738
// landscape_ratio13x10 - 1332 - 1025
// landscape_ratio13x10 - 1440 - 1108
// landscape_ratio13x10 - 1920 - 1477

// portrait_ratio15x23 - 343 - 526
// portrait_ratio15x23 - 440 - 675
// portrait_ratio15x23 - 666 - 1021
// portrait_ratio15x23 - 880 - 1349
// portrait_ratio15x23 - 960 - 1472
// portrait_ratio15x23 - 1080 - 1656
// portrait_ratio15x23 - 1332 - 2042
// portrait_ratio15x23 - 1440 - 2208
// portrait_ratio15x23 - 1920 - 2944

//grid
export const gridColumns = {
  xs: 4,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
}

//zIndex
export const Z_INDEX_LV8 = 100002
export const Z_INDEX_LV7 = 9999
export const Z_INDEX_LV6 = 9998
export const Z_INDEX_LV5 = 1300
export const Z_INDEX_LV4 = 4
export const Z_INDEX_LV3 = 3
export const Z_INDEX_LV2 = 2
export const Z_INDEX_LV1 = 1

export const DEFAULT_DIALOG_Z_INDEX = Z_INDEX_LV5
export const Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR = Z_INDEX_LV8 //to make sure it is higher than the ROX configurator index

// Redirects for empty cart without item.teaserLXCallToActionSettings
export const PLP_LINK_BRANDS = 'eyewear-all'
export const PLP_LINK_CONTACT_LENSES = 'contact-lenses'
export const DASH_CHARACTER = '-'
export const SLASH_CHARACTER = '/'
export const SEPARATOR_CHARACTER = '|'
export const COLON_CHARACTER = ':'
export const TIME_SEPARATOR = ':'
