import { isEmpty, toNumber } from 'lodash-es'
import { IPrescription } from '../../features/prescription/query'
import Log from '../../services/Log'
import { OrderItemContactLensData } from '../../types/cart'
import { OrderItem } from '../../types/order'
import { PrescriptionObject } from '../../types/rxConfigurator'
import { PrescriptionFormData } from '../../types/prescription'
import { SiteInfo } from '@redux/rootReducer'
export const FLOW_TYPE_MAP_KEY = 'rxFlowTypeMap'
export const LENSES_REVIEW_ATTRIBUTE_NAME = 'lensesReview'
export const MDT_DISCOUNT_AMOUNT = 'mdtDiscountAmount'
export const MDT_DISCOUNT_SEGMENT = 'mdtDiscountSegment'
export const CORRECTION_TYPE = {
  SINGLE_VISION: 'SINGLE VISION',
  READING: 'READING',
  PROGRESSIVE: 'PROGRESSIVE',
}

export type LensReviewItemType = {
  key: string
  description: string
}

interface TreatmentDetail {
  sequence: number
  name: string
  description?: string
  listPrice?: number
  offerPrice?: number
  children?: TreatmentDetail[]
}

export type LensesReviewRoxObjectInterface = {
  type: TreatmentDetail
  treatmentsFamily: TreatmentDetail
  thickness: TreatmentDetail
  addOns: TreatmentDetail
  alreadyIncluded: TreatmentDetail
}

export const createDatabaseRxFormat = (prescriptionObject, userDetails, storeID) => {
  const has2Pds = prescriptionObject.PD_OD !== null && prescriptionObject.PD_OS !== null
  const isNewRx = prescriptionObject.prescriptionId === null
  return {
    storeId: storeID,
    prescription: {
      id: isNewRx ? null : `${prescriptionObject.prescriptionId}`,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      telephone: userDetails?.phone1,
      nickName: prescriptionObject.name?.trim(),
      rightSphere: `${prescriptionObject.SPH_OD || '0.0'}`,
      rightAdd: `${prescriptionObject.ADD_OD || '0'}`,
      rightAxis: `${prescriptionObject.AX_OD || '0'}`,
      rightCyl: `${prescriptionObject.CYL_OD || '0.0'}`,
      leftSphere: `${prescriptionObject.SPH_OS || '0.0'}`,
      leftAdd: `${prescriptionObject.ADD_OS || '0'}`,
      leftAxis: `${prescriptionObject.AX_OS || '0'}`,
      leftCyl: `${prescriptionObject.CYL_OS || '0.0'}`,
      lPupDistance: has2Pds ? `${prescriptionObject.PD_OS.toFixed(1)}` || '0.0' : '0.0',
      rPupDistance: has2Pds ? `${prescriptionObject.PD_OD.toFixed(1)}` || '0.0' : '0.0',
      pupillaryDistance: has2Pds ? '0.0' : `${prescriptionObject.PD_OD.toFixed(1) || '0.0'}`,
    },
  }
}

export const generateRxListForConfigurator = (prescriptions: IPrescription[]) => {
  const validPrescriptions = prescriptions.filter(rx => {
    const { leftAxisError, rightAxisError, pdError } = validateRx(rx)
    return !(leftAxisError || rightAxisError || pdError)
  })

  const sortedRx = sortedPrescriptions(validPrescriptions).map(rx => {
    return {
      prescriptionId: toNumber(rx.idXprescription),
      SPH: {
        OD: toNumber(rx.rightSphere),
        OS: toNumber(rx.leftSphere),
      },
      CYL: {
        OD: toNumber(rx.rightCylinder),
        OS: toNumber(rx.leftCylinder),
      },
      AX: {
        OD: toNumber(rx.rightAxis || '0'),
        OS: toNumber(rx.leftAxis || '0'),
      },
      ADD: {
        OD: toNumber(rx.rightAdd || '0.0'),
        OS: toNumber(rx.leftAdd || '0.0'),
      },
      PD: {
        OD: toNumber(rx.rPupDistance !== '0.0' ? rx.rPupDistance : rx.pupillaryDistance),
        OS: toNumber(rx.lPupDistance),
      },
      name: rx.nickName,
      lastUpdate: rx.dateCreated,
      issueDate: rx.dateCreated,
    }
  })

  return {
    hasInvalidRx: validPrescriptions.length < prescriptions.length,
    prescriptions: sortedRx,
  }
}

export const sortedPrescriptions = (prescriptions: IPrescription[]): IPrescription[] => {
  return prescriptions
    .slice()
    .filter(rx => rx.markIfDeleted !== 'true' && rx.nickName !== 'null')
    .sort((a, b) => toNumber(b.idXprescription) - toNumber(a.idXprescription))
}

export const getRxValuesFromRxLens = (
  rxlensItem: OrderItem,
  options?: { fallbackValue: string }
): IPrescription | null => {
  if (!rxlensItem) {
    Log.warn('There are no available roxable items')
    return null
  }
  Log.info(`Getting Lenses RX Values: ${JSON.stringify(rxlensItem)}`)

  try {
    const lensPrescription = rxlensItem['orderItemExtendAttribute']?.find(
      attr => attr.attributeName === 'x_lens_prescription'
    )
    const rxValues = lensPrescription && JSON.parse(lensPrescription.attributeValue)
    const lPupDistance = rxValues?.['x_PD_left'] || options?.fallbackValue
    const rPupDistance = rxValues?.['x_PD_right'] || options?.fallbackValue
    const pupillaryDistance = lPupDistance && rPupDistance ? '0' : rxValues?.['x_Pupillary_distance'] || '0'

    return {
      rightSphere: rxValues?.['x_spherePower_right'] || options?.fallbackValue,
      rightAdd: rxValues?.['x_Add_right'] || options?.fallbackValue,
      rightAxis: rxValues?.['x_Axis_right'] || options?.fallbackValue,
      rightCylinder: rxValues?.['x_Cyl_right'] || options?.fallbackValue,
      leftSphere: rxValues?.['x_spherePower_left'] || options?.fallbackValue,
      leftAdd: rxValues?.['x_Add_left'] || options?.fallbackValue,
      leftAxis: rxValues?.['x_Axis_left'] || options?.fallbackValue,
      leftCylinder: rxValues?.['x_Cyl_left'] || options?.fallbackValue,
      lPupDistance: lPupDistance,
      rPupDistance: rPupDistance,
      pupillaryDistance: pupillaryDistance || options?.fallbackValue,
    }
  } catch (e) {
    Log.error('Could not retrieve RX from order Item: ' + e)
    return null
  }
}

export const isValidRxValue = (rxValue: string | undefined): boolean => {
  return Boolean(rxValue) && rxValue !== 'null'
}

export const getFormattedLensData = (cldata: OrderItemContactLensData | null): OrderItemContactLensData | null => {
  let orderItemClData = cldata

  if (orderItemClData) {
    orderItemClData = { ...cldata }

    //The BE does not accept values without the leading zeroes, but the FE should hide them
    if (orderItemClData.left.data?.x_axis) {
      orderItemClData.left.data.x_axis = `${toNumber(orderItemClData.left.data.x_axis)}`
    }
    if (orderItemClData.right.data?.x_axis) {
      orderItemClData.right.data.x_axis = `${toNumber(orderItemClData.right.data.x_axis)}`
    }
  }
  return orderItemClData
}

/**
 * Convert the prescription object to ROX specific format
 */
export const toROXPrescriptionObject = (rxObject: IPrescription | null): PrescriptionObject => {
  if (!rxObject) return {}

  return {
    SPH:
      rxObject?.rightSphere || rxObject?.leftSphere
        ? {
            OD: rxObject?.rightSphere || '0',
            OS: rxObject?.leftSphere || '0',
          }
        : null,
    CYL:
      rxObject?.rightCylinder || rxObject?.leftCylinder
        ? {
            OD: rxObject?.rightCylinder || '0',
            OS: rxObject?.leftCylinder || '0',
          }
        : null,
    AX:
      rxObject?.rightAxis || rxObject?.leftAxis
        ? {
            OD: rxObject?.rightAxis || '0',
            OS: rxObject?.leftAxis || '0',
          }
        : null,
    ADD:
      rxObject?.rightAdd || rxObject?.leftAdd
        ? {
            OD: rxObject?.rightAdd || '0',
            OS: rxObject?.leftAdd || '0',
          }
        : null,
    PD:
      rxObject?.rPupDistance || rxObject?.lPupDistance
        ? {
            OD: rxObject?.rPupDistance || null,
            OS: rxObject?.lPupDistance || null,
          }
        : null,
  }
}

export interface rxErrors {
  leftAxisError: boolean
  rightAxisError: boolean
  pdError: boolean
}

/*
 As we don't have clear requirements for all the RX constraints, I am following
 the rules applied on ROX configurator for axis and we can expand later
 */
export const validateRx = (prescriptionData: PrescriptionFormData | IPrescription | null): rxErrors => {
  const errors = { leftAxisError: false, rightAxisError: false, pdError: false }
  if (!prescriptionData) {
    return errors
  }
  errors.leftAxisError =
    (toNumber(prescriptionData.leftCylinder ?? prescriptionData.leftCyl) !== 0 &&
      toNumber(prescriptionData.leftAxis) === 0) ||
    (toNumber(prescriptionData.leftCylinder ?? prescriptionData.leftCyl) === 0 &&
      toNumber(prescriptionData.leftAxis) !== 0)
  errors.rightAxisError =
    (toNumber(prescriptionData.rightCylinder ?? prescriptionData.rightCyl) !== 0 &&
      toNumber(prescriptionData.rightAxis) === 0) ||
    (toNumber(prescriptionData.rightCylinder ?? prescriptionData.rightCyl) === 0 &&
      toNumber(prescriptionData.rightAxis) !== 0)

  errors.pdError =
    !prescriptionData.rPupDistance && !prescriptionData.lPupDistance && !prescriptionData.pupillaryDistance

  return errors
}

export const formatClDataForMobileView = (orderItemClData, showQuantityPerEye = false) => {
  if (!orderItemClData) return null

  const dataKeys = Object.keys(orderItemClData.left?.data || orderItemClData.right?.data || {})
  const formattedData = {
    eye: {
      left: orderItemClData.left?.data ? 'Left' : null,
      right: orderItemClData.right?.data ? 'Right' : null,
    },
  }
  dataKeys.forEach(key => {
    formattedData[key] = {
      left: orderItemClData.left?.data[key],
      right: orderItemClData.right?.data[key],
    }
  })

  if (showQuantityPerEye) {
    formattedData['Boxes'] = {
      left: toNumber(orderItemClData.left?.quantity),
      right: toNumber(orderItemClData.right?.quantity),
    }
  }

  return formattedData
}

export const reviewObjectKeyLanguageMapping = {
  advancedPrescription: {
    en: 'Prescription:',
    fr: 'Ordonnance:',
  },
  treatmentsFamily: {
    en: 'Lens type:',
    fr: "Type d'objectif :",
  },
  type: {
    en: 'Vision need:',
    fr: 'Besoin en matière de vision :',
  },
  color: {
    en: 'Color:',
    fr: 'Couleur :',
  },
  thickness: {
    en: 'Thickness:',
    fr: 'Épaisseur :',
  },
  addOns: {
    en: 'Finishings:',
    fr: 'Finitions :',
  },
  alreadyIncluded: {
    en: 'Already Included:',
    fr: 'Déjà inclus :',
  },
  brand: {
    en: 'Brand:',
    fr: 'Marque :',
  },
  transitionColor: {
    en: 'Color:',
    fr: 'Couleur :',
  },
  designType: {
    en: 'Design Type:',
    fr: 'Type de conception :',
  },
  treatments: {
    en: 'Treatment:',
    fr: 'Traitement :',
  },
}

/**
 * This function returns an array because we want to have control over the elements position without relying on sort algorithms
 * @param orderRxItem the OrderitemId
 * @returns an array with the review items
 */
export const getLensReviewObject = (orderRxItem: OrderItem, siteInfo: SiteInfo | null): LensReviewItemType[] => {
  const parsedObject: LensReviewItemType[] = []
  const lensesReview = JSON.parse(
    orderRxItem.orderItemExtendAttribute.flat().find(item => item.attributeName === LENSES_REVIEW_ATTRIBUTE_NAME)
      ?.attributeValue ?? '{}'
  )

  //TODO remove this conditional naming after we move to RXC3 for good, since the object naming differs from 2 to 3
  const type = lensesReview.type ? 'type' : 'Type'
  const advancedPrescription = lensesReview.advancedPrescription ? 'advancedPrescription' : 'AdvancedPrescription'
  const treatmentsFamily = lensesReview.treatmentsFamily ? 'treatmentsFamily' : 'TreatmentsFamily'
  const color = lensesReview.color ? 'color' : 'Color'
  const thickness = lensesReview.thickness ? 'thickness' : 'Thickness'
  const addOns = lensesReview.addOns ? 'addOns' : 'AddOns'
  const alreadyIncluded = lensesReview.alreadyIncluded ? 'alreadyIncluded' : 'AlreadyIncluded'
  const brand = lensesReview.brand ? 'brand' : 'Brand'
  const transitionColor = lensesReview.transitionColor ? 'transitionColor' : 'TransitionColor'
  const designType = lensesReview.designType ? 'designType' : 'DesignType'
  const treatments = lensesReview.treatments ? 'treatments' : 'Treatments'
  const langMap = reviewObjectKeyLanguageMapping
  const [language] = siteInfo?.locale.split('_') || ['en']

  !!lensesReview[type]?.name &&
    parsedObject.push({
      key: langMap.type[language],
      description: lensesReview[type].description,
    })

  !!lensesReview[advancedPrescription] &&
    parsedObject.push({
      key: langMap.advancedPrescription[language],
      description: lensesReview[advancedPrescription]?.description,
    })

  !!lensesReview[brand]?.name &&
    parsedObject.push({
      key: langMap.brand[language],
      description: lensesReview[brand].description,
    })

  !!lensesReview[treatmentsFamily]?.name &&
    parsedObject.push({
      key: langMap.treatmentsFamily[language],
      description: lensesReview[treatmentsFamily].description,
    })

  !!lensesReview[treatments]?.name &&
    parsedObject.push({
      key: langMap.treatments[language],
      description: lensesReview[treatments].description,
    })

  !!lensesReview[color]?.name &&
    parsedObject.push({
      key: langMap.color[language],
      description: lensesReview[color].description,
    })

  !!lensesReview[transitionColor]?.name &&
    parsedObject.push({
      key: langMap.transitionColor[language],
      description: lensesReview[transitionColor].description,
    })

  !!lensesReview[designType]?.name &&
    parsedObject.push({
      key: langMap.designType[language],
      description: lensesReview[designType].description,
    })

  !!lensesReview[thickness]?.name &&
    parsedObject.push({
      key: langMap.thickness[language],
      description: lensesReview[thickness].description,
    })

  if (!isEmpty(lensesReview[addOns]?.children)) {
    const addonsList = Array.from(
      new Set(lensesReview[addOns].children.filter(obj => obj.name.indexOf(',') === -1).map(obj => obj.name))
    )
    parsedObject.push({
      key: langMap.addOns[language],
      description: addonsList.join(', '),
    })
  }

  if (!!lensesReview[alreadyIncluded]?.description || !isEmpty(lensesReview[alreadyIncluded]?.children)) {
    const alreadyIncludedList = lensesReview[alreadyIncluded]?.description
      ? [lensesReview[alreadyIncluded]?.description]
      : Array.from(
          new Set(
            lensesReview[alreadyIncluded].children.filter(obj => obj.name.indexOf(',') === -1).map(obj => obj.name)
          )
        )
    parsedObject.push({
      key: langMap.alreadyIncluded[language],
      description: alreadyIncludedList.join(', '),
    })
  }

  return parsedObject
}
