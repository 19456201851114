import {
  CommonData,
  PLPData,
  ProductForAnalytics,
  ProductForAnalyticsFields,
} from '@foundation/analytics/tealium/interfaces'
import { VIRTUAL_PAGE_VIEW_EVENT_ID, sendAnalyticEventRaw } from '@foundation/analytics/tealium/lib'
// import { FrameGeniusType } from '@hooks/useFrameGenius'
import SizeAdvisorUtil from '@utils/FrameGenius/SizeAdvisorUtil'
import {
  getBrand,
  getFrameMaterial,
  getFrameShape,
  getFrontColor,
  getLensType,
  getLensesColor,
  getLensesTreatment,
  getModelName,
  getProductType,
  getSoldOut,
} from '@utils/productAttributesAlgolia'
import { padDecimals } from '@utils/productPrice'
import {
  ANALYTICS_PRODUCT_CATEGORY_MAP,
  USER_SEGMENT_COOKIE_NAME,
  USER_SEGMENT_COOKIE_VALUES_SEPARATOR,
  USER_SEGMENT_GUEST,
  USER_SEGMENT_REGISTERED_USERS,
} from '@constants/common'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import { PRODUCT_STATUS, TRACKER_ADDTOWISHLIST } from '@foundation/analytics/tealium/constants/tracker'
import Cookies from 'js-cookie'

import { ANALYTICS_PAGE_TYPE } from '../../foundation/hooks/useAnalyticsData'
import { IAlgoliaHit } from '@typesApp/product'
import {
  getproductStatuswithOptionsForAnalytics,
  LENS_TYPE,
} from '@foundation/analytics/tealium/formatters/productFormatter'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
export const ATTRIBUTES_TRANSLATED = 'attributes_translated.'

export const getAttributesFromUrl = (parameters, customerSegment: string) => {
  const keys = Object.keys(parameters ?? {})
  const attributes = {}
  keys.forEach(key => {
    if (key.startsWith(ATTRIBUTES_TRANSLATED) || key.startsWith('attributes.')) {
      attributes[key] = Array.isArray(parameters[key]) ? parameters[key] : [parameters[key]]
    }
    if (key.startsWith('discount')) {
      key = `sortDiscount_${customerSegment}`
      parameters[key] = parameters['discount']
      attributes[key] = Array.isArray(parameters[key]) ? parameters[key] : [parameters[key]]
    }
  })
  //removes special characters that are read from URL
  for (const facet in attributes) {
    attributes[facet] = attributes[facet].map((el: string) => decodeURIComponent(el.replace(/\+/g, ' ')))
  }
  return attributes
}

export const getHingeRangeAttributeFromUrl = (parameters): string => {
  const hingeRangeAttributeName = `${ATTRIBUTES_TRANSLATED}HINGE_DISTANCE_RANGE`
  const hingeRangeFacet = parameters[hingeRangeAttributeName]

  if (hingeRangeFacet) {
    return `${hingeRangeAttributeName}:${hingeRangeFacet}`
  }

  return ''
}

export const transformAttachmentsToImage = attachments => {
  return attachments
    ? attachments.map(({ id, identifier, name, rule, url, sequence }) => ({
        attachementAssetID: id,
        identifier,
        name,
        usage: rule,
        attachmentAssetPathRaw: url,
        sequence: sequence.toString(),
      }))
    : null
}

export const INDICES_MAP = {
  BEST_SELLERS: 0,
  NEW_ARRIVALS: 5,
  PRICE_ASC: 3,
  PRICE_DESC: 4,
  QUERY_SUGGESTIONS: -1,
}

const formatProduct = (product, islogged?: boolean): ProductForAnalyticsFields => {
  const type = getProductType(product)?.toUpperCase() || ''

  let offerPrice = 0
  let listPrice = 0
  const productCategory = ANALYTICS_PRODUCT_CATEGORY_MAP[type] || type
  if (product.prices) {
    Object.keys(product.prices).forEach(key => {
      const itemPrice = product.prices[key]
      if (islogged && itemPrice.segment === USER_SEGMENT_REGISTERED_USERS) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      } else if (!islogged && itemPrice.segment === USER_SEGMENT_GUEST) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      }
    })
  }
  const lensType = getLensType(product).toLowerCase()

  const { Status, OosOptions } = getproductStatuswithOptionsForAnalytics(product)

  const productContext: ProductForAnalyticsFields = {
    Case: '',
    Cloth: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status,
    OosOptions,
    Category: productCategory.toUpperCase(),
    Type: product.rxPrice ? LENS_TYPE.RX : LENS_TYPE.STD,
    LensType: !lensType || lensType === 'non_prescription' ? LENS_TYPE.PLANO : LENS_TYPE.RX,
    Price: padDecimals(offerPrice),
    PriceFull: padDecimals(listPrice),
    Brand: getBrand(product),
    Sku: `${product.productId}`,
    ModelName: getModelName(product),
    MoCo: `${product.name}`,
    LensColor: getLensesColor(product),
    LensTechnology: getLensesTreatment(product),
    FrameColor: getFrontColor(product),
    FrameTechnology: getFrameMaterial(product),
    Shape: getFrameShape(product),
    LensUPC: '',
    Visibility: 'Public',
    FrameType: '',
    Units: '1',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '', // TODO
  }

  return productContext
}

export const getProductsForAnalytics = (products): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p) => {
    if (p.partnumberId) {
      acc[p.partnumberId] = {
        ...formatProduct(p),
      }
    }

    return acc
  }, {})
}

export const sendPlpEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: any
  pageSection: string
}): void => {
  const analyticsProducts = getProductsForAnalytics(data.products)
  const dataToSend: PLPData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Plp',
    Page_Section1: data.pageSection,
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Editorial',
    Products: analyticsProducts,
  }
  sendAnalyticEventRaw(dataToSend)
}

export const getProductStatus = (product: any): string => {
  const soldOutLabel = getSoldOut(product).toUpperCase()
  switch (soldOutLabel) {
    case '':
    case PRODUCT_SOLDOUT_STATUS.NONE: {
      return PRODUCT_STATUS.AVAILABLE
    }
    case PRODUCT_SOLDOUT_STATUS.SOLDOUT: {
      return PRODUCT_STATUS.SOLD_OUT
    }
    case PRODUCT_SOLDOUT_STATUS.COMING_SOON: {
      return PRODUCT_STATUS.COMING_SOON
    }
    default:
      return PRODUCT_STATUS.AVAILABLE
  }
}

export const storeAlgoliaEventParameters = ({
  type,
  ...args
}: {
  type?: string
  queryID?: any
  indexName?: string
  position?: number
  objectID?: string
  filters?: string[]
}) => {
  Object.keys(args).forEach(key => {
    sessionStorage.setItem([type, key].filter(Boolean).join('_'), args[key])
  })
}

export const getAlgoliaEventParameters = (
  keys: string[],
  type?: string | undefined
): { queryID: string | undefined; indexName: string | undefined } => {
  return keys.reduce(
    (acc, curr) => {
      acc[curr] = sessionStorage.getItem([type, curr].filter(Boolean).join('_'))
      return acc
    },
    { queryID: undefined, indexName: undefined, position: undefined }
  )
}

export const clearAlgoliaEventParameters = (keys: string[], type?: string | undefined): void => {
  return keys.forEach(key => {
    sessionStorage.removeItem([type, key].filter(Boolean).join('_'))
  })
}

const formatFacetsForAnalytics = (facets): string => {
  let facetsValue = ''

  Object.keys(facets).forEach((key, i, array) => {
    if (facets[key] && facets[key].length) {
      facets[key].forEach(facet => {
        facetsValue += `${key.split('.')[1]}=${facet}`
        if (i !== array.length - 1) {
          facetsValue += '|'
        }
      })
      if (i !== array.length - 1) {
        facetsValue += '|'
      }
    }
  })

  return facetsValue
}

export const sendFilterSelectedEvent = (
  filters: { [key: string]: string[] },
  count?: number
  // frameGeniusData?: FrameGeniusType
) => {
  const isFrameGeniusToggleEnabled = SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  // const frameAdvisorData = getFrameAdvisorAnalyticsData(frameGeniusData)

  const facetsValue = formatFacetsForAnalytics(filters)

  const dataToSend = {
    // ...frameAdvisorData,
    id: 'SearchFilterUpdated',
    Search_FacetValues_String: facetsValue,
    Search_ResultItemsQnt: count?.toString(),
    Events_SearchFiltering: '1',
    Search_FacetValues_Type: !!isFrameGeniusToggleEnabled ? 'framegenius' : '', // standard, framegenius
  }

  sendAnalyticEventRaw(dataToSend)
}

const formatAlgoliaProduct = (item: any, pageType: string, islogged?: boolean): Partial<ProductForAnalyticsFields> => {
  const type = getProductType(item)?.toUpperCase() || ''

  let offerPrice = 0
  let listPrice = 0
  const productCategory = ANALYTICS_PRODUCT_CATEGORY_MAP[type] || type
  if (item.prices) {
    Object.keys(item.prices).forEach(key => {
      const itemPrice = item.prices[key]
      if (
        (islogged &&
          (itemPrice.segment === USER_SEGMENT_REGISTERED_USERS || itemPrice.segment === USER_SEGMENT_GUEST)) ||
        (!islogged && itemPrice.segment === USER_SEGMENT_GUEST)
      ) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      }
    })
  }

  let productContext: Partial<ProductForAnalyticsFields> = {
    Category: productCategory,
    LensType:
      pageType === ANALYTICS_PAGE_TYPE.PLP || getLensType(item).toLowerCase() === 'non_prescription'
        ? LENS_TYPE.PLANO
        : LENS_TYPE.RX,
    Price: padDecimals(offerPrice),
    PriceFull: padDecimals(listPrice),
    Brand: getBrand(item),
    Sku: `${item.productId}`,
    ModelName: getModelName(item),
    LensTechnology: getLensesTreatment(item),
    FrameTechnology: getFrameMaterial(item),
    Shape: getFrameShape(item),
    FrameType: '',
    Engraving: '',
  }

  return productContext
}

export const getAlgoliaProductsForAnalytics = (
  product: IAlgoliaHit,
  pageType: string,
  islogged?: boolean
): ProductForAnalytics => {
  if (product.partnumberId) {
    return {
      [product.partnumberId]: {
        ...formatAlgoliaProduct(product, pageType, islogged),
      },
    }
  }
  return {}
}

export const sendPLPAddToFavoritesEvent = (product: IAlgoliaHit, islogged?: boolean) => {
  const dataToSend = {
    id: TRACKER_ADDTOWISHLIST,
    Events_ProdFavAdd: '1',
    Products: getAlgoliaProductsForAnalytics(product, ANALYTICS_PAGE_TYPE.PLP, islogged),
  }
  sendAnalyticEventRaw(dataToSend)
}

export const getAlgoliaObjectIds = (hits): string[] | undefined => {
  if (!hits) return

  return hits.filter(hit => !!hit.objectID).map(hit => hit.objectID)
}
