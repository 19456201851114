import { CURRENCY_SYMBOLS } from '@constants/common'

class CurrencyService {
  getSymbolByName(currencyName: string): string {
    return CURRENCY_SYMBOLS[currencyName] || CURRENCY_SYMBOLS.DEFAULT
  }

  /**
   * Returns the currency code for the given locale or USD if the locale is not in the list
   * @param locale - the locale string, e.g. "en_au" or "fr_ca"
   * @returns the currency code
   */
  getCurrencyByLocale(locale: string): string {
    switch (locale.toLowerCase()) {
      case 'fr_ca':
      case 'en_ca':
        return 'CAD'
      case 'en_nz':
        return 'NZD'
      case 'en_au':
        return 'AUD'
      default:
        return 'USD'
    }
  }

  /**
   * Function used to show the prices in the proper format on PDPs, Your Bag, Shipping and Thank You pages
   * @param locale the store locale
   * @param currency the current format
   * @param currentPrice the full proce
   * @param priceRatio the price ratio to be considered when with discount, for example for easy refill
   * @param quantity the amount of times the price will be applied
   * @returns
   */
  getFormattedPrice = (
    locale: string,
    currency: string,
    currentPrice,
    priceRatio = 1,
    quantity = 1,
    maskZeroValue = false
  ): string | number => {
    const value: number = !!quantity ? parseFloat(currentPrice) * quantity * priceRatio : 0
    const formattedValue = Intl.NumberFormat(locale.replace('_', '-'), {
      style: 'currency',
      currency: currency || this.getCurrencyByLocale(locale),
      maximumFractionDigits: 2,
    }).format(value)

    return maskZeroValue ? formattedValue.replace(/\d+/g, '-') : formattedValue
  }
  //refer to https://luxotticaretail.atlassian.net/wiki/spaces/ROXC/pages/1112703328/Integration+guide#Currency-format
  getFormattedPriceForRoxConfigurator = (locale, currency) => {
    const isFRLocale = locale === 'fr_ca'
    const priceFormat = {
      thousandSeparator: isFRLocale ? '.' : ',',
      decimalSeparator: isFRLocale ? ',' : '.',
      decimalScale: 2,
    }
    priceFormat[isFRLocale ? 'suffix' : 'prefix'] = this.getSymbolByName(currency)

    return priceFormat
  }
}
const currencyService = new CurrencyService()
export default currencyService
