import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '@configs/index'

const WIDGET_CONFIG = {
  BUSINESS_UNIT_ID: {
    'en-ww':
      '588ae68e0000ff00059b8cfb' /* Need to default en-WW to en-CA to prevent CMS preview from breaking CLY-1596 */,
    'en-ca': '588ae68e0000ff00059b8cfb',
    'fr-fr': '588ae68e0000ff00059b8cfb',
    'en-au': '5cff8d583aacbf000176c225',
    'en-nz': '5d91d4daa325070001a4c9e3',
  },
}

export interface TrustPilotReviewResponse {
  links: {
    href: string
    method: string
    rel: string
  }[]
  numberOfReviews: {
    total: number
    oneStar: number
    twoStars: number
    threeStars: number
    fourStars: number
    fiveStars: number
  }
  starsAverage: number
}

export const trustPilotApi = createApi({
  reducerPath: 'trustPilotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.trustpilot.com/v1/product-reviews/business-units',
  }),
  endpoints: builder => ({
    getReviewCountForSku: builder.query<number, { sku: string; locale: string }>({
      query: ({ sku, locale }) => ({
        url: `${WIDGET_CONFIG.BUSINESS_UNIT_ID[locale]}/imported-reviews-summaries?sku=${sku}`,
        headers: { apikey: config.trustPilot.apiKey },
      }),
      transformResponse: (response: TrustPilotReviewResponse) => {
        const numberOfReviews = response.numberOfReviews.total
        return isNaN(numberOfReviews) || numberOfReviews === 0 ? 0 : numberOfReviews
      },
    }),
  }),
})

export const { useGetReviewCountForSkuQuery } = trustPilotApi
