import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type OnBehalfState = {
  isActive: boolean
  isLocked: boolean
}

const initialState: OnBehalfState = {
  isActive: false,
  isLocked: false,
}

export const onBehalfSlice = createSlice({
  name: 'onBehalf',
  initialState,
  reducers: {
    setOnBehalfStatus(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload
    },
    setOnbehalfLockStatus(state, action: PayloadAction<boolean>) {
      state.isLocked = action.payload
    },
  },
})

export const { setOnBehalfStatus, setOnbehalfLockStatus } = onBehalfSlice.actions
