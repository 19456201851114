import axios, { AxiosRequestConfig, Method, AxiosPromise } from 'axios'
import { API_CALL_ACTION } from '@redux/actions/api'
import RequestService, { RequestProps } from '@services/RequestService'
import { Attribute, Cart, IOrderDetails, Order, OrderItem, OrderTotals } from '@typesApp/order'
import {
  ORDER_EXTEND_ATTRIBUTE_NAMES,
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  DELIVERED_STATUSES,
  PENDING_PRESCRIPTION_STATUSES,
  SHIPPED_STATUSES,
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS,
  TaxValues,
  IS_RETURN_ENABLED,
  ORDER_STATUS_ISSUES,
} from '@constants/order'

import { toNumber } from 'lodash-es'
import { getOrderDiscountAmount } from '@utils/cart'
import { OrderItemInfo } from '@typesApp/orderReturn'
import { CheckoutPayload } from '@typesApp/checkout'
import { getCookieByName } from '@utils/cookie'
import { getOrderItemTaxFilters, getOrderSalesTaxFilters } from '@features/order/utils'
import DateService from '@services/DateService'
import {
  ADJUSTMENT_USAGE,
  EMPTY_STRING,
  EXCEPTION,
  HANDLING_FEES_ADJUSTMENT,
  HANDLING_FEES_DISCOUNT,
  HYPHEN,
  ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE,
  SPACE,
} from '@constants/common'
import { getSite } from '@foundation/hooks/useSite'
import { PRODUCTION, SHOW_API_FLOW } from '@foundation/constants/common'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { executeRequest } from '@foundation/axios/axiosConfig'
import { site as siteConstant } from '@foundation/constants/site'
import { isClAccessoriesOrderItem, isClOrderItem } from '@utils/order'
import { ProductTypesEnum } from '@typesApp/product'
import { RETURN_DAYS_LIMIT, RETURN_DAYS_DEFAULT, RETURN_DAYS_EXTENDED_LIMIT } from '@constants/returns'
import { isItemSubscribed, isSubscriptionOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import config from '@configs/config.base'
import { endOfDay } from 'date-fns'

class OrderService {
  readonly GET_HISTORY_REQUEST_PAGE_SIZE: number = 10

  /**
   * Gets the order details for a specific order ID.
   * `@method`
   * `@name Order#findByOrderId`
   * `@property {string} orderId (required)` The order identifier
   */
  findByOrderId({ orderId, storeId, ...rest }: { orderId: string; storeId?: string } & Partial<RequestProps>) {
    return RequestService.request<IOrderDetails>({
      ...rest,
      method: 'GET',
      path: '/store/{storeId}/order/{orderId}',
      pathParams: { orderId, storeId },
    })
  }

  /**
  * Find order by the parent order ID.
  * `@method`
  * `@name Order#findByParentOrderId`
  *
  * `@param {any} headers (optional)` will add headers to rest request
  *
  * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
  *
  * `@param {any} parameters` have following properties:
     ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.

   ** `@property {string} orderId (required)` The order identifier.
   ** `@property {string} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {string} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
  */
  findByParentOrderId(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    let siteContext = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    const domain = url || siteContext
    const path = '/store/{storeId}/order'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error("Request '/store/{storeId}/order' missing path parameter storeId")
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    queryParameters.set('q', 'findByParentOrderId')

    if (parameters['orderId'] === undefined) {
      throw new Error("Request '/store/{storeId}/order' missing required parameter orderId")
    }
    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow = process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('@redux/store').default
      if (store) {
        store.dispatch(API_CALL_ACTION(from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters))
      }
    }

    return executeRequest(requestOptions)
  }

  paypalExpressCheckStatus(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/paypal/checkStatus/@self',
    })
  }

  isRoxable(orderExtendAttribute: Order['orderExtendAttribute'] = []): string {
    return (
      orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.IS_ROX_ORDER)?.attributeValue ||
      ''
    )
  }

  getTrackingNumbers(orderExtendAttribute: Order['orderExtendAttribute'] = []): string[] {
    try {
      const trackingIds =
        orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.TRACK_NUMBER)?.attributeValue ||
        '{}'
      const trackingIdsObject = JSON.parse(trackingIds)
      return trackingIdsObject['tracking-codes'].filter(Boolean)
    } catch (e) {
      return []
    }
  }

  getOrderItemTrackingNumber(orderId: string, orderItemExtendAttribute: Attribute<string>[]): string {
    const trackingNumber =
      orderItemExtendAttribute.find(
        a => a.attributeName === ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(orderId)
      )?.attributeValue || ''

    return trackingNumber
  }

  getSubscriptionRecurrency(orderItemExtendAttribute: Attribute<string>[]): string {
    const subscriptionInterval =
      orderItemExtendAttribute?.find(a => a.attributeName === ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE)?.attributeValue || ''

    return subscriptionInterval
  }

  getOrderItemTaxes(orderId: string, orderItemExtendAttribute?: Attribute<string>[]): TaxValues {
    if (orderItemExtendAttribute == null) {
      return {
        provincial: 0,
        federal: 0,
      }
    }
    const provincialTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      getOrderItemTaxFilters.isProvincial(orderId, attribute)
    )

    const countryTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      getOrderItemTaxFilters.isFederal(orderId, attribute)
    )

    const provincialTaxes = provincialTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    const federalTaxes = countryTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    return {
      provincial: provincialTaxes,
      federal: federalTaxes,
    }
  }

  /**
   * Shipping and handling  taxes are stored in the order attributes
   * @param orderItemExtendAttribute order item extend attributes
   * @returns object with provincial and federal taxes
   */
  getOrderSalesTaxes(orderItemExtendAttribute?: Attribute<string>[]): TaxValues {
    if (orderItemExtendAttribute == null) {
      return {
        provincial: 0,
        federal: 0,
      }
    }

    const provincialTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      getOrderSalesTaxFilters.isProvincial(attribute)
    )

    const countryTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      getOrderSalesTaxFilters.isFederal(attribute)
    )

    const provincialTaxes = provincialTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    const federalTaxes = countryTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    return {
      provincial: provincialTaxes,
      federal: federalTaxes,
    }
  }

  getOrderItemReturnDaysLeft(orderDetails: IOrderDetails, orderItemInfo: OrderItemInfo): number {
    const { orderId } = orderDetails
    const { orderItemId } = orderItemInfo
    const { extensionStartDate, extensionEndDate } = config.orderReturns
    const orderDate = new Date(orderDetails?.placedDate || '')
    const orderDateNumber = Number(new Date(orderDate))
    const startDateNumber = Number(new Date(extensionStartDate))
    const endDateNumber = Number(endOfDay(new Date(extensionEndDate)))
    let shouldUseExtendedReturn = false
    if (orderDateNumber && startDateNumber && endDateNumber) {
      shouldUseExtendedReturn = orderDateNumber >= startDateNumber && orderDateNumber <= endDateNumber
    }

    // NOTE: keeping fallback to constants to keep the app running if env values are NaN/undefined
    const returnDaysLimit = shouldUseExtendedReturn
      ? Number(config.orderReturns.daysExtendedLimit) || RETURN_DAYS_EXTENDED_LIMIT
      : Number(config.orderReturns.daysLimit) || RETURN_DAYS_LIMIT

    const orderItemDetails = orderDetails.orderItem.find(oI => oI.orderItemId === orderItemId)
    if (!orderItemDetails) return Number(config.orderReturns.daysDefaultLimit) || RETURN_DAYS_DEFAULT

    const deliveredDate = this.getOrderItemDeliveredDate(orderId, orderItemDetails)
    if (!deliveredDate) return Number(config.orderReturns.daysDefaultLimit) || RETURN_DAYS_DEFAULT

    const elapsedDays = DateService.getElapsedDays(deliveredDate)
    if (elapsedDays < 0) return Number(config.orderReturns.daysDefaultLimit) || RETURN_DAYS_DEFAULT

    const returnDaysLeft = returnDaysLimit - Math.floor(elapsedDays)
    return Math.max(0, returnDaysLeft)
  }

  getOrderTaxes(
    order: Cart | IOrderDetails | null,
    orderId: string,
    orderItems: OrderItem[],
    itemizedTaxes: boolean
  ): TaxValues {
    const totalTaxes = orderItems?.reduce(
      (accum, current) => {
        const itemTaxes = this.getOrderItemTaxes(orderId, current?.orderItemExtendAttribute)
        return {
          provincial: accum.provincial + itemTaxes.provincial,
          federal: accum.federal + itemTaxes.federal,
        }
      },
      { provincial: 0, federal: 0 } as TaxValues
    ) || { provincial: 0, federal: 0 }

    if (itemizedTaxes && order) {
      const orderTaxes = this.getOrderSalesTaxes(order.orderExtendAttribute)
      totalTaxes.provincial += orderTaxes ? orderTaxes.provincial : 0
      totalTaxes.federal += orderTaxes ? orderTaxes.federal : 0
    }

    return totalTaxes
  }

  getItemsCount(order: Order): number {
    return parseInt(order?.x_orderItemsCount ?? 0)
  }

  isLegacyReturn(order: Order, n1SiteGoliveDate: string): boolean {
    let placedDate
    if (order && order.placedDate) placedDate = new Date(order.placedDate)
    return placedDate < new Date(n1SiteGoliveDate)
  }

  isReturnEligible(order: Order, n1SiteGoliveDate: string): boolean {
    const returnableStatuses = [
      ORDER_STATUS.Settled, // This is considered as Shipped on FE
      ORDER_STATUS.Delivered,
    ]

    return (
      IS_RETURN_ENABLED &&
      returnableStatuses.includes(order?.orderStatus) &&
      DateService.getElapsedDays(order?.lastUpdateDate) <= 30 &&
      !this.isLegacyReturn(order, n1SiteGoliveDate)
    )
  }

  /**
   * @returns true if order is mix order (contains CL) and order status is eligible for reorder
   */
  isReorderEligible(order: IOrderDetails | Order): boolean {
    const eligibleStatuses = [...SHIPPED_STATUSES, ORDER_STATUS.Delivered, ORDER_STATUS.Cancelled]

    // Disable 3-click reorder for migrated orders until data for those orders are available
    const isMigratedOrder = order?.orderDescription && order?.orderDescription?.toUpperCase()?.indexOf('IMPORT') >= 0
    // 1-click reorder is available for migrated orders
    if (isMigratedOrder && !config.isOneClickReorderEnabled) {
      return false
    }
    const isOrderWithSubscription = isSubscriptionOrder(order)
    return eligibleStatuses.includes(order?.orderStatus) && !isOrderWithSubscription
  }

  isPendingPrescription(order: Order): boolean {
    return PENDING_PRESCRIPTION_STATUSES.includes(order?.orderStatus)
  }

  isShipped(order: Order): boolean {
    return SHIPPED_STATUSES.includes(order?.orderStatus)
  }

  isDelivered(order: Order): boolean {
    return ORDER_STATUS.Delivered === order?.orderStatus
  }

  isCompleted(order: Order): boolean {
    return DELIVERED_STATUSES.includes(order?.orderStatus)
  }

  isShippedSettled(order: Order): boolean {
    return order?.orderStatus === ORDER_ITEM_STATUS.Shipped_Settled
  }

  hasIssue(order: Order): boolean {
    return ORDER_STATUS_ISSUES.includes(order?.orderStatus)
  }

  getFirstOrderItemThumbnail(order: Order): string {
    return order?.x_firstOrderItemThumbnail || ''
  }

  getOrderItemsThumbnails(order: Order): string[] {
    return order?.x_orderItemsThumbnailsArray || []
  }

  getOrderEstimatedDeliveryDates(order: Order): string[] {
    return order?.x_estimatedDeliveryDatesArray || []
  }

  getOrderItemEstimatedDeliveryDate(orderItem: OrderItem): string {
    return (
      orderItem?.orderItemExtendAttribute?.find(attr => attr.attributeName === 'X_ESTIMATED_DELIVERY_DATE')
        ?.attributeValue || ''
    )
  }

  getOrderItemDeliveredDate(orderId: string, orderItem: OrderItem): string {
    return (
      orderItem?.orderItemExtendAttribute?.find(attr => attr.attributeName === `${orderId}:DELIVERED_DATE`)
        ?.attributeValue || ''
    )
  }

  orderContainsNonReorderableItem = (orderItems: OrderItem[]): boolean => {
    if (!!orderItems) {
      for (const orderItem of orderItems) {
        if (
          !(isClOrderItem(orderItem.orderItemExtendAttribute) || isClAccessoriesOrderItem(orderItem.attributes)) ||
          isItemSubscribed(orderItem)
        ) {
          return true
        }
      }
    }
    return false
  }

  hasReorderableItem = (order: OrderItem[] | Order): boolean => {
    if (!order) return false
    if (Array.isArray(order) && order.length) {
      const reorderableArray = order.map(orderItem => {
        return isClOrderItem(orderItem.orderItemExtendAttribute) || isClAccessoriesOrderItem(orderItem.attributes)
      })
      return reorderableArray.includes(true)
    }
    if ('x_productTypes' in order) {
      return (
        (order.x_productTypes?.includes(ProductTypesEnum.ContactLenses) ||
          order.x_productTypes?.includes(ProductTypesEnum.ContactLensesAccessories)) ??
        false
      )
    }
    return false
  }

  hasInsuranceApplied(order: Order): boolean {
    return (
      order?.orderExtendAttribute?.find(attr => attr.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.X_CLAIM_APPLIED)
        ?.attributeValue === 'true'
    )
  }

  /**
   * @returns order totals summary
   */
  getOrderTotals(order: Order | null): OrderTotals {
    const totals: OrderTotals = {
      hasTax: false,
      hasShipping: false,
      hasShippingTax: false,
      hasInsurance: false,
      hasPromotion: false,
      subtotal: 0,
      tax: 0,
      shipping: 0,
      shippingTax: 0,
      handlingFeeAmount: 0,
      handlingFeeTotal: 0,
      promotionAmount: 0,
      insuranceAmount: 0,
      totalAdjustment: 0,
      grandTotal: 0,
      shippingCurrency: order?.totalShippingChargeCurrency || order?.grandTotalCurrency || '',
      shippingTaxCurrency: order?.totalShippingTaxCurrency || order?.grandTotalCurrency || '',
      promotionCurrency: order?.totalAdjustmentCurrency || order?.grandTotalCurrency || '',
      insuranceCurrency: order?.grandTotalCurrency || '',
      grandTotalCurrency: order?.grandTotalCurrency || '',
    }

    if (!order) {
      return totals
    }

    totals.hasTax = !!order.totalSalesTax
    totals.hasShipping = !!order.totalShippingCharge
    totals.hasShippingTax = !!order.totalShippingTax
    totals.hasInsurance = this.hasInsuranceApplied(order)

    totals.subtotal = parseFloat(order.totalProductPrice || '0')
    totals.tax = parseFloat(order.totalSalesTax || '0')
    totals.shipping = parseFloat(order.totalShippingCharge || '0')
    totals.shippingTax = parseFloat(order.totalShippingTax || '0')
    totals.totalAdjustment = Math.abs(getOrderDiscountAmount(order))
    totals.grandTotal = parseFloat(order.grandTotal || '0')
    totals.insuranceAmount = parseFloat(
      order.orderExtendAttribute?.find(attr => attr.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.X_CLAIM_TOTAL_AMOUNT)
        ?.attributeValue || '0'
    )
    const handlingFeeSurcharge = (order?.adjustment ?? [])
      .filter(
        adj => adj.description === HANDLING_FEES_ADJUSTMENT && adj.usage.toLowerCase() === ADJUSTMENT_USAGE.SURCHARGE
      )
      .reduce((acc, adj) => acc + toNumber(adj.amount), 0)
    const handlingFeeDiscount = (order?.adjustment ?? [])
      .filter(
        adj => adj.description === HANDLING_FEES_DISCOUNT && adj.usage.toLowerCase() === ADJUSTMENT_USAGE.DISCOUNT
      )
      .reduce((acc, adj) => acc + toNumber(adj.amount), 0)
    let promotionsAmount = 0

    const EXCLUDED_DISCOUNTS = [HANDLING_FEES_ADJUSTMENT, HANDLING_FEES_DISCOUNT]

    if (order.adjustment && order.adjustment.length > 0) {
      promotionsAmount = order.adjustment
        .map(obj => {
          return !EXCLUDED_DISCOUNTS.includes(obj.description) ? toNumber(obj.amount || '0') : 0
        })
        .reduce(function (accum: number, current: number) {
          return accum + current
        }, 0)
    }

    if (handlingFeeSurcharge > 0) {
      totals.handlingFeeAmount = handlingFeeSurcharge
      totals.handlingFeeTotal = handlingFeeSurcharge + handlingFeeDiscount
    }

    const totalAdjustments = Math.abs(parseFloat(order.totalAdjustment || '0'))
    totals.hasPromotion = Math.abs(promotionsAmount) > 0 && totalAdjustments >= totals.insuranceAmount
    if (totals.hasPromotion) {
      totals.promotionAmount = promotionsAmount
    }

    return totals
  }

  getDisplayShippingName(orderItems: OrderItem[] = []): string {
    const shippingName = orderItems[0]?.shipModeDescription || orderItems[0]?.shipModeCode
    return shippingName?.replaceAll(EXCEPTION, EMPTY_STRING).replaceAll(HYPHEN, SPACE)
  }

  async requestInvoice({ storeId, orderId }): Promise<any> {
    try {
      const siteContext: string = siteConstant.transactionContextUrl
      const { data, headers } = await axios.get(`${siteContext}/store/${storeId}/sapinvoice/${orderId}/download`, {
        responseType: 'arraybuffer',
      })
      const base64Prescription = Buffer.from(data, 'binary').toString('base64')
      const stream = await fetch(`data:${headers['content-type']};base64,${base64Prescription}`)
      return await stream.blob()
    } catch (e) {
      return []
    }
  }
}

const orderService = new OrderService()

export default orderService
