import { HASH_NAVIGATION_ELEMENT_TIMEOUT, HASH_NAVIGATION_PREFIX } from '@constants/navigation'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'

export const waitForLazyElementToLoad = async (
  selector: string,
  timeout = HASH_NAVIGATION_ELEMENT_TIMEOUT
): Promise<Element | undefined> => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const element = document.querySelector(selector)
      if (element) {
        clearInterval(interval)
        resolve(element)
      }
    }, 100)
    setTimeout(() => {
      clearInterval(interval)
      resolve(undefined)
    }, timeout)
  })
}

export const generateAnchorID = (placementName: string): string => {
  return `${HASH_NAVIGATION_PREFIX}${placementName}`
}

type ScrollBehavior = 'auto' | 'smooth' | 'instant'

export const scrollPageToTop = (behavior: ScrollBehavior = 'instant') => {
  document.getElementById(APP_MAIN_CONTAINER_ID)?.scrollTo({ top: 0, behavior })
}
