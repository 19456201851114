import { Redirect } from 'next/types'
import {
  CONTACT_LENS_BASE_CURVE_COLUMN,
  CONTACT_LENS_DIAMETER_COLUMN,
  CONTACT_LENS_SPHERE_POWER_COLUMN,
} from '../constants/common'
import { AlternateURL } from '../foundation/seo/SEO'
import { EyeClFieldConfig, EyeClFieldConfigMap, IProduct } from '../types/product'
import { StoreConfigType } from '@typesApp/store'
import { getClLensRange } from './productAttributes'
import { PlacementCounter } from '@typesApp/common'
import { isMtoProduct } from './product'
import { ErrorLevel, generateMessage } from '@services/Log'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import config from '@configs/config.base'

const BASE_CURVE_POSITION = 0
const SPHERE_POWER_POSITION = 1
const DIAMETER_POSITION = 2
const LENS_RANGE_VALUES_SEPARATOR = ' '
const CL_LENS_RANGE = 'CL_LENS_RANGE'

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
/**
 *
 */
export function executeOnce<Args extends unknown[], Return>(callback: (...args: Args) => Return, id: string) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = callback(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const uniqueId = ((): ((prefix: string) => string) => {
  let counter = 0
  return (prefix: string): string => `${prefix}${++counter}`
})()

export const browserInUse = (): string => {
  const sUsrAg = window.navigator.userAgent
  let browser: string | null = null
  switch (true) {
    case sUsrAg.indexOf('Firefox') > -1:
      browser = 'Firefox'
      break
    case sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1:
      browser = 'Opera'
      break
    case sUsrAg.indexOf('Trident') > -1:
      browser = 'Explorer'
      break
    case sUsrAg.indexOf('Edge') > -1:
      browser = 'Edge'
      break
    case sUsrAg.indexOf('Chrome') > -1:
      browser = 'Chrome'
      break
    case sUsrAg.indexOf('Safari') > -1:
      browser = 'Safari'
      break
    default:
      browser = 'unknown'
      break
  }
  return browser
}

export const notNull = <T>(x: T | null): x is T => x !== null
export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined

// create function using localeCompare to compare strings
export const areEquals = (a?: string, b?: string): boolean => {
  if (!a || !b) return false
  return a?.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
}

export const stringToBoolean = (s?: string | boolean): boolean => {
  if (!s) return false
  if (typeof s === 'boolean') return s
  return s?.toLowerCase() === 'true'
}

export const booleanToString = (b: boolean): string => {
  return `${b}`
}

/**
 * Removes double // from urls or paths avoiding the protocals
 * @param pathOrUrl {string}  "a//url/path", "https://a.com//url/path"
 * @returns {string} "a/url/path", "https://a.com/url/path"
 */
export const formatPathOrUrl = (pathOrUrl: string) => {
  const [protocol, path] = pathOrUrl.split('://')
  if (!path) return pathOrUrl.replace(/\/{2,}/g, '/')
  const formattedPath = path.replace(/\/{2,}/g, '/')
  return `${protocol}://${formattedPath}`
}

/**
 * Converts string in array format to array of strings
 * @param {string}  "[07/04/2024, 07/04/2024]"
 * @returns {array} ["07/04/2024", "07/04/2024"]
 */
export const stringArrayToArray = (strArray?: string): string[] => {
  if (!strArray) return []
  if (strArray.length < 3 || typeof strArray !== 'string') return []
  return strArray
    .slice(1, -1)
    .split(',')
    .map(s => s.trim())
}

export const sortByDateString = (dates: string[], desc = false): string[] => {
  return dates
    .map(date => {
      const [d, m, y] = date.split('/')
      return `${y}-${m}-${d}`
    })
    .sort((a, b) => {
      const [dateA, dateB] = [new Date(a).getTime(), new Date(b).getTime()]
      return desc ? dateB - dateA : dateA - dateB
    })
    .map(date => {
      const [y, m, d] = date.split('-')
      return `${d}/${m}/${y}`
    })
}

export const isIOS = (): boolean =>
  typeof navigator !== 'undefined'
    ? navigator.userAgent
      ? /iPad|iPhone|iPod/.test(navigator.userAgent)
      : false
    : false

export const clientHeight = (): number => window.innerHeight || document?.documentElement.clientHeight

type RedirectTo = {
  destination: string
  permanent?: boolean
}

export type RedirectReturn = {
  redirect: Redirect
}
export const redirectTo = ({ destination, permanent = false }: RedirectTo): RedirectReturn => {
  return {
    redirect: {
      permanent,
      destination,
    },
  }
}

export const openNewTab = (url: string, fileName?: string): void => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('target', '_blank')
  link.setAttribute('rel', 'noopener noreferrer')
  link.style.visibility = 'hidden'
  fileName && (link.download = fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const PDF_MIME_TYPE = 'data:application/pdf;base64,'

export const getPageSection = (
  identifier: string,
  sectionLevel: number,
  alternateHrefs?: Array<AlternateURL>
): string => {
  if (!identifier || sectionLevel < 1) return ''

  const localizedIdentifier = identifier.startsWith('fr-')
    ? alternateHrefs?.find(al => al.key === 'en-ca')?.value.substring(1)
    : identifier
  const splitPageSection = localizedIdentifier ? localizedIdentifier.split('/') : identifier.split('/')

  return splitPageSection?.[sectionLevel] || ''
}

export interface FilteredValueItemsProps {
  right?: string[]
  left?: string[]
}
export interface FilteredValuesProps {
  baseCurveValues: FilteredValueItemsProps
  diameterValues: FilteredValueItemsProps
  powerSphereValues: FilteredValueItemsProps
}

const resetAllFieldOptions = (lensRangeValues, isMto: boolean, ...fields) => {
  if (lensRangeValues && fields?.length) {
    fields.forEach(field => {
      field.options.forEach((element, index) => {
        element.notAvailable =
          !isMto &&
          index > 0 &&
          getFilteredValues(field.id, lensRangeValues, element.text || element.value)[field.id].length === 0
      })
    })
  }
}

const enableFieldValues = (field: EyeClFieldConfig | undefined, filteredValues: string[], isMto: boolean) => {
  field?.options.forEach(
    element => (element.notAvailable = (!isMto && element.value && !filteredValues.includes(element.text)) || false)
  )
}

/**
 * @param fieldId  x_diameter, x_baseCurve or x_spherePower
 * @param lensRangeValues This is recovered from the product attribute CL_LENS_RANGE
 * @param value used to identify that the range contains that field value
 * @returns only the ranges that contain the field/value combination will be returned
 */
const getFilteredValues = (fieldId: string, lensRangeValues: string[], value: string): { [key: string]: any[] } => {
  const fieldPosition =
    fieldId === CONTACT_LENS_SPHERE_POWER_COLUMN
      ? SPHERE_POWER_POSITION
      : fieldId === CONTACT_LENS_BASE_CURVE_COLUMN
        ? BASE_CURVE_POSITION
        : DIAMETER_POSITION
  const filteredValues = lensRangeValues.filter(val =>
    value ? val.split(LENS_RANGE_VALUES_SEPARATOR)[fieldPosition] === value : true
  )

  return {
    x_baseCurve: filteredValues.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[BASE_CURVE_POSITION]),
    x_spherePower: filteredValues?.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[SPHERE_POWER_POSITION]),
    x_diameter: filteredValues.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[DIAMETER_POSITION]),
  }
}
/**
 * Filters the data to be shown in the drop down select based on the product CL_LENS_RANGE attribute
 * @param contactLensFieldsConfig the current product field configuration to be displayed
 * @param product  the curent Server product
 * @param product the server product to be analyzed
 * @param eye left or right
 * @param id x_diameter, x_baseCurve or x_spherePower
 * @param value the value to be filtered
 * @returns For every range item, it will enable or disable the field, based on the product CL_LENS_RANGE configuration
 */
export const updateLensRangeFieldsBySelectedValue = (
  contactLensFieldsConfig: EyeClFieldConfigMap | null,
  product: IProduct,
  eye,
  id,
  value
): EyeClFieldConfigMap | null => {
  const lensRangeValues = getClLensRange(product)
  const isMto = isMtoProduct(product)

  if (lensRangeValues) {
    const diameterField = contactLensFieldsConfig?.[eye].find(item => item.id === CONTACT_LENS_DIAMETER_COLUMN)
    const baseCurveField = contactLensFieldsConfig?.[eye].find(item => item.id === CONTACT_LENS_BASE_CURVE_COLUMN)
    const powerSphereField = contactLensFieldsConfig?.[eye].find(item => item.id === CONTACT_LENS_SPHERE_POWER_COLUMN)

    if (id === CONTACT_LENS_SPHERE_POWER_COLUMN) {
      if (!value) {
        resetAllFieldOptions(lensRangeValues, isMto, diameterField, baseCurveField)
      } else {
        const { x_baseCurve, x_diameter } = getFilteredValues(id, lensRangeValues, value)
        enableFieldValues(diameterField, x_diameter, isMto)
        enableFieldValues(baseCurveField, x_baseCurve, isMto)
      }
    } else if (id === CONTACT_LENS_BASE_CURVE_COLUMN) {
      if (!value) {
        resetAllFieldOptions(lensRangeValues, isMto, diameterField, powerSphereField)
      } else {
        const { x_diameter, x_spherePower } = getFilteredValues(id, lensRangeValues, value)
        enableFieldValues(diameterField, x_diameter, isMto)
        enableFieldValues(powerSphereField, x_spherePower, isMto)
      }
    } else if (id === CONTACT_LENS_DIAMETER_COLUMN) {
      if (!value) {
        resetAllFieldOptions(lensRangeValues, isMto, baseCurveField, powerSphereField)
      } else {
        const { x_baseCurve, x_spherePower } = getFilteredValues(id, lensRangeValues, value)
        enableFieldValues(baseCurveField, x_baseCurve, isMto)
        enableFieldValues(powerSphereField, x_spherePower, isMto)
      }
    }
  }

  return contactLensFieldsConfig
}
/**
 * Filters the data to be shown in the drop down select based on the product CL_LENS_RANGE attribute
 * @param contactLensFieldsConfig  the current product field configuration to be displayed
 * @param product  the curent Server product
 * @returns a enriched field config data marking all the fields that were not found in the CL_LENS_RANGE as disabled, so the customer cannot
 * mistakenly add an invalid combination to the cart
 */
export const loadValidProductLensRangeValues = (
  contactLensFieldsConfig: EyeClFieldConfigMap | null,
  product: IProduct
): EyeClFieldConfigMap | null => {
  const lensRangeValues = product.productAttributes[CL_LENS_RANGE]
  if (lensRangeValues && contactLensFieldsConfig) {
    const eyes = ['left', 'right']
    const isMto = isMtoProduct(product)

    eyes.forEach(eye => {
      const diameterField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_DIAMETER_COLUMN)
      const baseCurveField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_BASE_CURVE_COLUMN)
      const powerSphereField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_SPHERE_POWER_COLUMN)
      resetAllFieldOptions(lensRangeValues, isMto, diameterField, baseCurveField, powerSphereField)
    })
  }

  return contactLensFieldsConfig
}

export const getCartIdForAnalytics = (cartId: string): string =>
  cartId ? cartId : sessionStorage.getItem('tealium_data_cart_id') || ''

export const getStoresConfigForCountrySelector = (): StoreConfigType[] => {
  return [
    {
      countryCode: 'au',
      countryLanguage: 'en',
      storeId: '70202',
      locale: 'en_au',
    },
    {
      countryCode: 'ca',
      countryLanguage: 'en',
      storeId: '70201',
      locale: 'en_ca',
    },
    {
      countryCode: 'ca',
      countryLanguage: 'fr',
      storeId: '70201',
      locale: 'fr_ca',
    },
    {
      countryCode: 'nz',
      countryLanguage: 'en',
      storeId: '70203',
      locale: 'en_nz',
    },
    {
      countryCode: 'ww',
      countryLanguage: 'en',
      storeId: '70999',
      locale: 'en_ww',
    },
  ]
}

export const convertToPascalSnakeCase = (text = ''): string => {
  if (text.length === 0) return ''
  const words = text.includes('_') ? text.split('_') : text.split(' ')
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    })
    .join('_')
}

export const convertToPascal = (text = ''): string => {
  if (text.length === 0) return ''
  const words = text.includes('_') ? text.split('_') : text.split(' ')
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    })
    .join('')
}

export const trimText = (text: string, joinedBy = ''): string => {
  return !!text
    ? text
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
        })
        .join('')
        .toString() + joinedBy
    : ''
}

export const getDataElementId = (
  viewType: string | null,
  placementCounter?: PlacementCounter,
  type = 'IMG'
): string => {
  const placementIndex = placementCounter?.placementIndex ?? 0
  const tabIndex = placementCounter?.tabIndex ?? 0
  const tileIndex = placementCounter?.tileIndex ?? 0
  const teaserIndex = placementCounter?.teaserIndex ?? 0
  const actionIndex = placementCounter?.actionIndex ?? ''

  switch (viewType) {
    case 'grid-of-products':
    case 'cly-category-with-cta':
      return `${placementIndex}Placement_Tab${tabIndex}_Tile${tileIndex}_${type}`
    case 'boards-with-fields-below':
    case 'box-with-margin':
      return `${placementIndex}Placement_Tile${tileIndex}_${type}${actionIndex}`
    default:
      return `${placementIndex}Placement_Banner${teaserIndex}_${type}`
  }
}

/**
 * To enable the feature via Storage, include the following item in the local Storage : HCL--isSoldOutFeatureEnabled
 * For the storage config, it will only affect the current session
 * @returns true if there is a flag to enabled it in the environment config or local Storage configuration
 */
export const isSoldOutFeatureEnabled = () => {
  const isEnabledViaConfig = config.isSoldOutFeatureEnabled
  const isEnabledViaLocalStorage = localStorageUtil.get('isSoldOutFeatureEnabled')

  return !!isEnabledViaConfig || !!isEnabledViaLocalStorage
}

export const isEarlyAccesFeatureEnabled = () => {
  return config.isEarlyAccessFeatureEnabled
}

export const isEarlyAccessFeatureNewsLetterEnabled = () => {
  return config.isEarlyAccessFeatureEnabled && config.isEarlyAccessFeatureNewsLetterEnabled
}

export const convertToLocale = (locale: string) => {
  return locale.toLowerCase().replace('_', '-')
}
