import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import qs from 'qs'

import {
  ICommerceHclPage,
  IFooter,
  IHeader,
  IMegaMenu,
  IPage,
  IPageStaticLanding,
  ICmsPreviewArgs,
  IQueryParams,
  ISitemapItem,
  ICmsCommerceArgs,
  IHCLArgs,
} from '@typesApp/cms'
import { RootReducerState } from '@redux/rootReducer'
import { withCredentialsEnabled, sanitizePageId } from '@utils/helpers'
import config from '@configs/index'

const storeName = config.name?.toLowerCase() as string
const getLang = (locale?: string) => locale?.replace('-', '_')?.split('_')?.[0] ?? 'en'
const getCountry = (country?: string) => country?.toLowerCase() ?? 'ca'
const getLangCountry = (locale?: string): string[] => {
  if (!locale) return config.defaultLocale.split('_')
  const [lang, country] = locale?.replace('-', '_')?.split('_')
  return [lang?.toLowerCase(), country?.toLowerCase()]
}
//TODO: use for test algolia
export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.cmsApiUrl,

    paramsSerializer: params => {
      if (!params) {
        return ''
      }
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    ...(withCredentialsEnabled() ? { credentials: 'include' } : {}),
  }),
  endpoints: builder => ({
    getHeader: builder.query<IHeader, Partial<IQueryParams>>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, storeID, langId, defaultLanguageID } = state?.site?.currentSite ?? {}
        const { previewDate, filterRulesLocaleOverride } = args
        const [lang, country] = getLangCountry(filterRulesLocaleOverride ?? locale)

        const params = {
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          includePlacements: args?.includePlacements,
          ...(previewDate && { previewDate }),
          ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/page/header/${storeName}/${lang}/${country}`,
          params: { ...params },
        })

        return (result.data as IHeader)
          ? { data: result.data as IHeader }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getMegaMenu: builder.query<IMegaMenu, Partial<IQueryParams>>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, storeID, langId, defaultLanguageID } = state?.site?.currentSite ?? {}
        const { previewDate, filterRulesLocaleOverride } = args
        const [lang, country] = getLangCountry(filterRulesLocaleOverride ?? locale)

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          ...(previewDate && { previewDate }),
          ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/page/mega-menu/${storeName}/${lang}/${country}`,
          params: { ...params },
        })

        return (result.data as IMegaMenu)
          ? { data: result.data as IMegaMenu }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPage: builder.query<IPage, Partial<IQueryParams>>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, storeID, langId } = state.site.currentSite!
        const { previewDate, filterRulesLocaleOverride } = args
        const [lang, country] = getLangCountry(filterRulesLocaleOverride ?? locale)

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          ...(previewDate && { previewDate }),
          ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/home/content/${storeName}/${lang}/${country}`,
          params: { ...params },
        })

        return (result.data as IPage) ? { data: result.data as IPage } : { error: result.error as FetchBaseQueryError }
      },
    }),
    getFooter: builder.query<IFooter, Partial<IQueryParams>>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, storeID, langId, defaultLanguageID } = state.site.currentSite!
        const { previewDate, filterRulesLocaleOverride } = args
        const currentLocale =
          filterRulesLocaleOverride && filterRulesLocaleOverride !== '' ? filterRulesLocaleOverride : locale
        const [lang, country] = getLangCountry(currentLocale)

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          ...(previewDate && { previewDate }),
          ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/page/footer/${storeName}/${lang}/${country}`,
          params: { ...params },
        })

        return (result.data as IFooter)
          ? { data: result.data as IFooter }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getHclPage: builder.query<ICommerceHclPage, IHCLArgs>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, storeID, defaultLanguageID, langId } = state.site.currentSite!
        const { previewDate, filterRulesLocaleOverride } = args
        const currentLocale =
          filterRulesLocaleOverride && filterRulesLocaleOverride !== '' ? filterRulesLocaleOverride : locale
        const [lang, country] = getLangCountry(currentLocale)

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          externalId: args.externalId,
          ...(previewDate && { previewDate }),
          ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/hcl/content/${storeName}/${lang}/${getCountry(country)}`,
          params,
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPlpCommerce: builder.query<ICommerceHclPage, IQueryParams>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId, defaultLanguageID } = state.site.currentSite!
        const { isEnabled, previewDate, filterRulesLocaleOverride } = state.preview

        const params: IQueryParams = {
          ...args,
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          ...(isEnabled && { previewDate }),
          ...(isEnabled && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/plp/content/${storeName}/${getLang(locale)}/${getCountry(country)}`,
          params: { ...params },
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPdpCommerce: builder.query<ICommerceHclPage, ICmsCommerceArgs>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId, defaultLanguageID } = state.site.currentSite!
        const { isEnabled, previewDate, filterRulesLocaleOverride } = state.preview

        const params: IQueryParams = {
          ...args,
          storeId: storeID,
          langId: langId ?? defaultLanguageID,
          ...(isEnabled && { previewDate }),
          ...(isEnabled && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/pdp/content/${storeName}/${getLang(locale)}/${getCountry(country)}`,
          params,
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPageStaticLandingRX: builder.query<IPageStaticLanding, string>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!
        const { isEnabled, previewDate, filterRulesLocaleOverride } = state.preview
        const pageId = sanitizePageId(args ?? '')
        const params: IQueryParams = {
          pageId,
          storeId: storeID,
          langId: langId,
          ...(isEnabled && { previewDate }),
          ...(isEnabled && { filterRulesLocaleOverride }),
        }
        const result = await baseQuery({
          url: `cms/live/page/content/${storeName}/${getLang(locale)}/${getCountry(country)}`,
          params,
        })
        return (result.data as IPageStaticLanding)
          ? { data: result.data as IPageStaticLanding }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPageStaticLanding: builder.query<IPageStaticLanding, IQueryParams>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!
        const { isEnabled, previewDate, filterRulesLocaleOverride } = state.preview
        const pageId = sanitizePageId(args?.pageId ?? '')
        const params: IQueryParams = {
          ...args,
          pageId,
          storeId: storeID,
          langId: langId,
          ...(isEnabled && { previewDate }),
          ...(isEnabled && { filterRulesLocaleOverride }),
        }

        const result = await baseQuery({
          url: `cms/live/page/content/${storeName}/${getLang(locale)}/${getCountry(country)}`,
          params: { ...params },
        })

        return (result.data as IPageStaticLanding)
          ? { data: result.data as IPageStaticLanding }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPreviewContent: builder.query<any, ICmsPreviewArgs>({
      query: args => ({
        url: `cms/preview/component/${args.id}`,
        params: args.params,
      }),
      transformResponse: (response: any) => response.content.content,
    }),
    getPreviewPage: builder.query<any, ICmsPreviewArgs>({
      query: args => ({
        url: `cms/preview/page/${args.id}`,
        params: args.params,
      }),
      transformResponse: (response: any, _meta, args) => {
        const { filterRulesLocaleOverride, previewDate } = args.params
        return {
          ...response,
          isEnabled: !!response,
          filterRulesLocaleOverride,
          previewDate,
        }
      },
    }),
    getSitemap: builder.query<ISitemapItem[], void>({
      query: () => ({
        url: `sitemap/${storeName}/en/ca`,
        params: { baseUrl: 'https://www.clearly.ca', json: true },
      }),
    }),
  }),
})

export const {
  useGetHeaderQuery,
  useGetMegaMenuQuery,
  useGetPageQuery,
  useGetPageStaticLandingQuery,
  useGetPageStaticLandingRXQuery,
  useGetFooterQuery,
  useGetHclPageQuery,
  useGetPlpCommerceQuery,
  useGetPdpCommerceQuery,
  useGetPreviewContentQuery,
  useGetPreviewPageQuery,
  useGetSitemapQuery,
} = cmsApi
