import initializeWunderkindDataLayer from '@components/Wunderkind/dataLayer'
import { isWunderkindEnabledForLocale } from '@components/Wunderkind/Wunderkind'
import { MainHead } from '@components/MainHead'
import PrescriptionLensesProvider from '@components/PrescriptionLenses/PrescriptionLensesContext'
import config from '@configs/index'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { INIT_SITE_SUCCESS_ACTION, SET_HOSTNAME, SET_HOSTNAME_URLS } from '@redux/actions/site'
import { SiteInfo } from '@redux/rootReducer'
import wrapper from '@redux/store/index'
import theme from '@themes/index'
import mediaQuery from 'css-mediaquery'
import { appWithTranslation } from 'next-i18next'
import NextApp, { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import { logger } from '../logging'
import { IHeader } from '@typesApp/cms'
import { CookiesProvider, parseCookies } from '@utils/Cookies'
import { serverInitialPropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { TmpCookiesObj } from 'cookies-next/lib/types'
import domainUrlsConfiguration from '../../configuration/config.json'

import '../index.scss'
import '../styles/index.scss'
import '../styles/global.scss'
// Swiper CSS. Import only needed modules
import 'swiper/scss'
import 'swiper/scss/effect-fade'
import 'swiper/scss/free-mode'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'
import { getHostnameUrls } from '@utils/getHostnameUrls'
import { NextPage } from 'next/types'
import { ReactElement, ReactNode, useEffect } from 'react'
import { useCheckForHashInUrl } from '@hooks/useCheckForHashInUrl'

export type Page<P = object> = NextPage<P> & {
  getLayout?: (page: ReactElement, pageProps: P) => ReactNode
}

type MyAppProps = AppProps & {
  deviceType: string
  cookies: TmpCookiesObj
  siteDataRes: SiteInfo
  header: IHeader
  locale: string
  hostnameUrls: Record<string, string>
  currentHostname: string
  Component: Page
}

export function MyApp({ Component, deviceType, cookies, hostnameUrls, currentHostname, locale, ...rest }: MyAppProps) {
  const { store, props } = wrapper.useWrappedStore(rest)
  const state = store.getState()
  const damDomain = state.site.currentSite?.xStoreCfg?.['damDomain']
  const cmsImageServerUrlPreconnect =
    domainUrlsConfiguration?.[currentHostname]?.cmsImageServerUrlPreconnect ?? config?.cmsImageServerUrlPreconnect

  useCheckForHashInUrl()

  useEffect(() => {
    if (isWunderkindEnabledForLocale(locale)) {
      initializeWunderkindDataLayer()
    }
  }, [])

  const { pageProps } = props
  const getLayout = Component.getLayout ?? (page => page)
  const ssrMatchMedia = query => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === 'mobile' ? '0px' : '1024px',
    }),
  })
  theme.components = {
    ...theme.components,
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia,
      },
    },
  }

  store.dispatch(SET_HOSTNAME_URLS(hostnameUrls))
  store.dispatch(SET_HOSTNAME(currentHostname))

  return (
    <>
      <MainHead damDomain={damDomain} cmsImageServerUrlPreconnect={cmsImageServerUrlPreconnect} />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CookiesProvider cookies={cookies}>
            <CssBaseline />
            <PrescriptionLensesProvider>
              {getLayout(<Component {...pageProps} />, pageProps)}
            </PrescriptionLensesProvider>
          </CookiesProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = serverInitialPropsWrapper<MyAppProps>(async (stateManager, context) => {
  let deviceType = 'desktop'
  const { req } = context.ctx

  const { hostname, hostnameUrls } = getHostnameUrls(req)

  if (req) {
    deviceType = UAParser(req.headers['user-agent']).device.type || 'desktop'
  }

  const getLocale = (locale?: string) => {
    const defaultLocale = config.defaultLocale
    if (!locale) return defaultLocale
    return config.availableLocales.find(l => l?.toLowerCase() === locale?.toLowerCase()) || defaultLocale
  }
  const locale = getLocale(context.ctx.locale)

  try {
    return {
      ...NextApp.getInitialProps(context),
      deviceType,
      cookies: parseCookies(context.ctx.req),
      hostnameUrls,
      locale,
      currentHostname: hostname,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    logger.error(
      `<<_app>> smth went wrong code: ${e.code || e.statusCode} message: ${e.message} url: ${e?.config?.url}`
    )
    return { initialProps: { error: e } }
  }
})

export default appWithTranslation(MyApp)
