// If updating please keep in sync with src/styles/_colors.scss
export const customPalette = {
  davidclulow: {
    cyprus: '#00303C',
    sherpaBlue: '#014758',
    halfBaked: '#83C8BB',
    jaggedIce: '#BCE7DF',
    lithCobalt: '#83C8BB',
    boulder: '#767676',
    alto: '#D8D8D8',
    wildSand: '#F6F6F6',
    white: '#FFFFFF',
    greenPea: '#247534',
    tulipTree: '#F0A63C',
    thunderBird: '#D32113',
    black: '#000000',
    grey: '#333333',
  },
  clearly: {
    cyprus: '#00303C',
    sherpaBlue: '#014758',
    halfBaked: '#83C8BB',
    jaggedIce: '#BCE7DF',
    boulder: '#767676',
    alto: '#D8D8D8',
    wildSand: '#F6F6F6',
    white: '#FFFFFF',
    greenPea: '#247534',
    tulipTree: '#F0A63C',
    thunderBird: '#D32113',
    black: '#000000',
    ghostWhite: '#F3F1FF',
    blue: '#1C4DA1',
    green: '#005D30',
    red: '#D51C29',
    yellow: '#FDB930',
    orange: '#ED4924',
    purple: '#43358D',
    brown: '#543C32',
    grey: '#3D3C3C',
    gold: '#CC9900',
    whiteSmoke: '#F8F8F8',
    light: {
      blue: '#C7E4F8',
      green: '#C6E6DB',
      red: '#FCD3C9',
      yellow: '#FFEFCF',
      orange: '#FDE3D7',
      purple: '#E7E5F0',
      grey: '#F1F2F2',
    },
    light1: {
      blue: '#4069B2',
      green: '#00703B',
      red: '#E4414B',
      yellow: '#FFD043',
      orange: '#F15F31',
      purple: '#52449B',
      grey: '#6F6E6F',
    },
    light2: {
      blue: '#6BA2D7',
      green: '#67A78C',
      red: '#EE888F',
      yellow: '#FFE39C',
      orange: '#F9AC93',
      purple: '#958CC3',
      grey: '#B8B8B8',
    },
    light3: {
      blue: '#F0F5FF',
      red: '#FDE1E1',
      grey: '#B0B0B0',
    },
    light4: {
      blue: '#DCEDFC',
    },
    dark1: {
      blue: '#253D7F',
      green: '#004C27',
      red: '#AD1F23',
      yellow: '#FAA424',
      orange: '#D64227',
      purple: '#352C76',
    },
  },
}
