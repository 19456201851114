import React, { PropsWithChildren, useContext, createContext, useState, useCallback, useMemo } from 'react'
import { getCookies as internalGetCookies, getCookie as internalGetCookie } from 'cookies-next'
import { AppContext } from 'next/app'
import config from '@configs/config.base'
import { TmpCookiesObj } from 'cookies-next/lib/types'
import { USER_SEGMENT_COOKIE_NAME, USER_SEGMENT_COOKIE_VALUES_SEPARATOR } from '@constants/common'
import { debounce } from './helpers'

export interface CookiesProviderProps {
  cookies: TmpCookiesObj
  refetchCookies: () => void
}

const CookiesContext = createContext<CookiesProviderProps | null>(null)

export const CookiesProvider: React.FC<PropsWithChildren<Partial<CookiesProviderProps>>> = (props): JSX.Element => {
  const { cookies, children } = props
  const [cookiesState, setCookiesState] = useState(cookies ?? {})
  const refetchCookies = useCallback(
    debounce(() => {
      const cookies = internalGetCookies()
      if (JSON.stringify(cookiesState?.userg) !== JSON.stringify(cookies?.userg)) {
        setCookiesState(cookies)
      }
    }, 500),
    [JSON.stringify(cookiesState?.userg)]
  )

  return <CookiesContext.Provider value={{ cookies: cookiesState, refetchCookies }}>{children}</CookiesContext.Provider>
}

export function useCookies() {
  const cookiesContext = useContext(CookiesContext)
  if (!cookiesContext) {
    throw new Error('useCookies must be used within a CookiesProvider')
  }
  return cookiesContext
}

export function parseCookies(req: AppContext['ctx']['req']) {
  return internalGetCookies({ req })
}

export function useCookie(name: string): string | undefined {
  const { cookies } = useCookies()
  return cookies?.[name]
}

/* Use when in context of a React Component, especially when request is being made from a Server-side component */
export function useCustomerSegmentsUtil(value?: string): string[] {
  const usergCookie = useCookie(USER_SEGMENT_COOKIE_NAME)
  const customerSegmentCookie = useMemo(() => {
    const cookie = value || usergCookie?.charAt(0) === '"' ? usergCookie?.slice(1, -1) : usergCookie // the userg is set by BE as a "fake" empty string
    return cookie ? cookie.split(USER_SEGMENT_COOKIE_VALUES_SEPARATOR) : [config?.algolia?.guestSegment]
  }, [usergCookie])
  return customerSegmentCookie
}

/* Use when in client-side, vanilla JS context. No hooks!  */
export function getCustomerSegmentsUtil(value?: string): string[] {
  const usergCookie = internalGetCookie(USER_SEGMENT_COOKIE_NAME)
  const cookie = value || usergCookie?.charAt(0) === '"' ? usergCookie?.slice(1, -1) : usergCookie // the userg is set by BE as a "fake" empty string
  return cookie ? cookie.split(USER_SEGMENT_COOKIE_VALUES_SEPARATOR) : [config?.algolia?.guestSegment]
}
