import { StateManagerSSR } from './stateManager'
import { StoreManagerLoadersTypeMap } from './types'

export class StoreInfoData extends StateManagerSSR<StoreManagerLoadersTypeMap['storeInfo']['locale']> {
  private static instance: StoreInfoData | null = null

  constructor() {
    super('siteInfo')
  }

  public static readonly newInstance = (): StoreInfoData => {
    if (this.instance) {
      return this.instance
    }
    const newInstance = new StoreInfoData()
    this.instance = newInstance
    return newInstance
  }
}

export const storeInfoDataInstance = StoreInfoData.newInstance()
