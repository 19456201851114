import {
  AccountEventData,
  CartData,
  CommonData,
  CountrySelectionData,
  DataToSend,
  DeliveryData,
  ErrorForAnalytics,
  ErrorNetworkData,
  InsuranceEventPayload,
  LoginData,
  NotFoundData,
  PaymentData,
  PDPData,
  ProductUpdateData,
  SearchFilteringPageData,
  SearchPageData,
  SearchRunPageData,
  TYPData,
  OrderSummaryData,
} from './interfaces'
import {
  TRACKER_ADDTOCART,
  TRACKER_ADDTOWISHLIST,
  TRACKER_MINICART_OVERLAY_OPEN,
  TRACKER_MOUSE_CLICK,
  TRACKER_NEWSLETTER_SIGNUP,
  TRACKER_PROMOCODE_TOGGLE,
  TRACKER_SEARCH,
} from './constants/tracker'
import {
  getOrderPaymentType,
  getOrderShippingDiscount,
  getOrderState,
  getOrderTotalDiscount,
  getOrderZipCode,
  getProductsForAnalytics,
  getProductsForTYPAnalytics,
} from './formatters/productFormatter'

import { OrderItem } from '../../../types/order'
import Log from '../../../services/Log'
import { ProductAnalyticsRX, ProductSoldOutStatus } from './../../../types/product'
import { IProduct } from '../../../types/product'
import { getFormattedError } from './formatters/formatError'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import SizeAdvisorUtil from '../../../utils/FrameGenius/SizeAdvisorUtil'
import { FrameGeniusType } from '@hooks/useFrameGenius'
import { USER_SEGMENT_GUEST, USER_SEGMENT_LOGGED } from '../../../constants/common'
import { ANALYTICS_PAGE_TYPE, getUserDetailsForAnalytics, getUserToken } from '../../hooks/useAnalyticsData'
import { sessionStorageUtil } from '../../utils/storageUtil'
import { UserDetails } from '../../../types/user'
import { getProductAlgoliaPrice, PartNumberAlgoliaPrice } from '../../algolia/algoliaPrice'
import { algoliaEventTrigger } from '@foundation/algolia/algoliaConfig'
import { getProductType } from '@utils/productAttributes'
import { IOrderSliceState } from '@features/order/IOrderSliceState'
import { isContactLensesProduct } from '@utils/product'
import { SearchRunType, SearchRunView } from './pageTypes'
import { getCustomerSegmentsUtil } from '@utils/Cookies'

export const TEALIUM_WAIT_TIME = 2000
export const VIRTUAL_PAGE_VIEW_EVENT_ID = 'VirtualPage-View'
export const ANALYTICS_IS_REORDER = 'analytics_is_reorder'

export function sendAnalyticEventRaw<D extends { id: string }>(data: D, sessionId?: D) {
  try {
    const tealiumPayload = sessionId
      ? {
          Session_Id: sessionId,
          ...data,
        }
      : data
    window.tealium_data2track = window.tealium_data2track || []
    window.tealium_data2track.push(tealiumPayload)
  } catch (e) {
    Log.error('Error sending raw analytics: ' + e, typeof window !== 'undefined' ? window.location.href : '')
  }
}

let sendAnalyticEvent = sendAnalyticEventRaw
if (typeof window !== 'undefined') {
  window.addEventListener('tealiumInit', () => {
    sendAnalyticEvent = sendAnalyticEventRaw
  })
}

export const sendLoadingReadyEvent = (pageType: string, pageSection: string): void => {
  // see tealium documentation, this event overwrites
  // the first page load event and aborts previous events
  // TODO refactor to use sendAnalitycEvent
  Log.info(`ANALYTICS - send page ready pagetype: ${pageType} pageSection: ${pageSection}`)
  let obj
  try {
    obj = {
      id: 'Loading-Ready',
      Page_Type: pageType, // include data object properties listed in Data Layer
      Page_Section_1: pageSection,
    }
  } catch (err) {
    obj = {
      id: 'Loading-Error',
      Error_Source: 'Server',
      Error_Code: 'utag_data object',
      Error_Details: 'Error setting ',
    }
  } finally {
    window.tealium_data2track && window.tealium_data2track.push(obj)
  }
  Log.info('ANALYTICS - Loading Ready Event Sent')
}

export const sendEventDiscountApplied = (commonData: CommonData): void => {
  Log.info('ANALYTICS - Event Discount Applied')
  let obj = {
    ...commonData,
    id: 'Event',
    Events_DiscountApplied: '1',
  }
  window.tealium_data2track && window.tealium_data2track.push(obj)
  Log.info('ANALYTICS - Event Discount Applied Sent')
}

export const sendServerErrorEvent = (error: ErrorNetworkData): void => {
  const dataToSend: ErrorForAnalytics = getFormattedError(error)
  sendAnalyticEvent<ErrorForAnalytics>(dataToSend)
}

type NotFoundErrorData = {
  common: CommonData
  requestedUrl: string
}

type SubscriptionErrorData = {
  common: CommonData
  errorCode: string
  errorDetails: string
  errorSource: 'User' | 'Server' | '404' | 'Product' | 'Anomaly'
  errorMessage: string
}

export const sendNotFoundErrorEvent = ({ common, requestedUrl }: NotFoundErrorData) => {
  const dataToSend: NotFoundData = {
    ...common,
    id: 'Event',
    Events_Error: '1',
    Page_Type: 'Error',
    Page_Section1: 'Other',
    Page_Section2: 'ErrorHttp404',
    Error_Source: '404',
    Error_Code: '404 - page not found',
    Error_Details: requestedUrl,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendCartSubscriptionErrorEvent = ({
  common,
  errorCode,
  errorDetails,
  errorSource,
  errorMessage,
}: SubscriptionErrorData) => {
  const dataToSend = {
    ...common,
    id: 'Event',
    Error_Code: errorCode,
    Error_Details: errorDetails,
    Events_Error: '1',
    Error_Source: errorSource,
    Error_Message: errorMessage,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendLoginEvent = (userDetails: UserDetails, commonData: CommonData): void => {
  const userDetailsForAnalytics: Partial<CommonData> = commonData

  getUserDetailsForAnalytics(userDetails, userDetailsForAnalytics, true)

  const keysToDelete = [
    'Order_CartId',
    'Order_CartUrl',
    'Order_Currency',
    'Page_Brand',
    'Page_Country',
    'Page_Design',
    'Page_DeviceType',
    'Page_Language',
    'Page_Platform',
    'Page_Section1',
    'Page_Section2',
    'Page_Server',
  ]

  keysToDelete.forEach(key => delete userDetailsForAnalytics[key])

  const dataToSend: LoginData = {
    ...userDetailsForAnalytics,
    id: 'Event',
    Events_UserLogin: '1',
  }

  sendAnalyticEvent(dataToSend)
}

type AccountEventPayload = {
  common: CommonData
  Page_Section1?: string
  Page_Section2?: string
  Page_Section3?: string
}

export const sendMyAccountEvent = ({ common, Page_Section1, Page_Section2, Page_Section3 }: AccountEventPayload) => {
  const dataToSend: AccountEventData = {
    ...common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Landing',
    Page_Section1: Page_Section1 ?? 'Account',
    Page_Section2: Page_Section2 ?? 'Static',
    Page_Section3: Page_Section3 ?? '',
  }
  sendAnalyticEvent(dataToSend)
}

export type PDPPageData = {
  common: CommonData
  loginStatus: boolean
  products: IProduct[]
  pageSection1: string
  pageSection2: string
  isUpcSupported: '0' | '1'
  isBrowserSupported: '0' | '1'
  vmIsSupported: string
  grouped_queryID: string | null
  ungrouped_queryID: string | null
  grouped_indexName: string | null
  ungrouped_indexName: string | null
  position: string | null
  ungrouped_position: string | null
  objectID: string | null
  filters: (string | null)[] | []
  soldOutStatus?: ProductSoldOutStatus
  hasRecommendations?: boolean
}

export const sendPdpEvent = (data: PDPPageData): void => {
  const {
    common,
    loginStatus,
    products,
    pageSection1,
    pageSection2,
    isUpcSupported,
    isBrowserSupported,
    grouped_queryID,
    ungrouped_queryID,
    grouped_indexName,
    ungrouped_indexName,
    position,
    ungrouped_position,
    objectID,
    filters,
    soldOutStatus,
    hasRecommendations,
  } = data
  const customerSegments = getCustomerSegmentsUtil()
  const algoliaPrices = getProductAlgoliaPrice(
    customerSegments,
    products?.[0]
  ) /* only sending current product in array */
  const analyticsProducts = getProductsForAnalytics({ products, soldOutStatus, algoliaPrices, hasRecommendations })
  const dataToSend: PDPData = {
    ...common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    User_LoginStatus: loginStatus ? USER_SEGMENT_LOGGED : USER_SEGMENT_GUEST,
    User_LoginType: 'Standard',
    Page_Type: 'Pdp',
    Page_Section1: pageSection1,
    Page_Section2: pageSection2,
    Products: analyticsProducts,
    Vm_IsUpcSupported: isUpcSupported,
    Vm_IsBrowserSupported: isBrowserSupported,
    grouped_queryID: grouped_queryID,
    ungrouped_queryID: ungrouped_queryID,
    position: position,
    grouped_indexName: grouped_indexName,
    ungrouped_indexName: ungrouped_indexName,
    ungrouped_position: ungrouped_position,
    objectIDs: [objectID],
    filters: filters,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendAddToFavoritesEvent = (products: IProduct[]): void => {
  const analyticsProducts = getProductsForAnalytics({ products })
  const dataToSend = {
    id: TRACKER_ADDTOWISHLIST,
    Events_ProdFavAdd: '1',
    Products: analyticsProducts,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendAddToCartEvent = (
  products: IProduct[],
  quantity: number,
  algoliaPrices?: PartNumberAlgoliaPrice,
  analyticsDataForCart?: Partial<CommonData>
) => {
  const [firstItem] = products
  const isCLProduct = isContactLensesProduct(firstItem)
  const productId: string = isCLProduct ? firstItem['relationship.item.id'] || '' : firstItem.partNumber

  const indexes = ['grouped', 'ungrouped']
  indexes.forEach(index => {
    algoliaEventTrigger('sendEvents', [
      {
        eventType: 'conversion',
        eventName: 'Event',
        index: sessionStorage.getItem(index + '_indexName') || '',
        userToken: getUserToken(),
        queryID: sessionStorage.getItem(index + '_queryID') || '',
        objectIDs: [productId],
      },
    ])
  })

  const analyticsProducts = getProductsForAnalytics({
    products,
    partNumberQuantities: new Map<string, number>([[firstItem.partNumber, quantity]]),
    algoliaPrices,
  })

  delete analyticsDataForCart?.Order_SubscriptionFrequency

  const dataToSend = {
    ...analyticsDataForCart,
    id: TRACKER_ADDTOCART,
    Products: analyticsProducts,
    Events_CartAdd: '1',
  }

  sendAnalyticEventRaw(dataToSend)
}

export const sendCountrySelectionEvent = (countryCode: string) => {
  const dataToSend: CountrySelectionData = {
    id: 'Click',
    countryCode,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendCartEvent = (data: {
  common: Partial<CommonData>
  loginStatus: boolean
  products: ProductAnalyticsRX[]
  partNumberQuantities: Map<string, number>
  algoliaPrices: PartNumberAlgoliaPrice
  clAccessoriesWithError?: string[]
}): void => {
  const { clAccessoriesWithError, products, partNumberQuantities, algoliaPrices } = data
  const analyticsProducts = getProductsForAnalytics({
    products: products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
    partNumberQuantities,
    algoliaPrices,
    clAccessoriesWithError,
  })
  const dataToSend: CartData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Design: data.products.length === 0 ? 'Empty' : data.common?.Page_Design,
    ...(Object.keys(analyticsProducts).length > 0 && { Products: analyticsProducts }),
  }
  sendAnalyticEvent(dataToSend)
}

export interface CouponSubmitPayload extends Partial<CommonData> {
  result: boolean
  Error_Source?: string
  Error_Code?: string
  Error_Message?: string
}

export interface PartQuantity {
  partNumber: string
  quantity: number
}

export const sendCouponSubmitEvent = ({
  result,
  Order_DiscountCode,
  Order_DiscountName,
  Error_Code,
  Error_Message,
  Error_Source,
}: CouponSubmitPayload): void => {
  const dataToSend: DataToSend = {
    id: result ? 'OrderDiscountCode-Applied' : 'OrderDiscountCode-Tentative',
    Order_DiscountCode,
    Order_DiscountName,
    Error_Code,
    Error_Message,
    Error_Source,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendDeliveryEvent = (data: {
  common: Partial<CommonData>
  products: IProduct[]
  partNumberQuantities: Map<string, number>
  algoliaPrices: PartNumberAlgoliaPrice
  shipMode?: string
}): void => {
  const { products, partNumberQuantities, algoliaPrices } = data
  const analyticsProducts = getProductsForAnalytics({
    products: products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
    partNumberQuantities,
    algoliaPrices,
  })
  const dataToSend: DeliveryData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: ANALYTICS_PAGE_TYPE.DELIVERY,
    Order_ShippingMode: data.shipMode ?? '',
    Products: analyticsProducts,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendTYPData = (data: {
  common?: Partial<CommonData>
  products: IProduct[]
  partNumberQuantities: Map<string, number>
  algoliaPrices: PartNumberAlgoliaPrice
  email?: string
  order: IOrderSliceState
  shipMode?: string
}): void => {
  const { orderDetails } = data.order

  if (!orderDetails) {
    return
  }

  const analyticsProducts = getProductsForTYPAnalytics(
    data.products,
    orderDetails,
    data.partNumberQuantities,
    data.algoliaPrices
  )

  const dataToSend: TYPData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Products: analyticsProducts,
    User_EmailOptin: '0',
    Order_CartId: orderDetails?.orderId,
    Order_Currency: orderDetails.grandTotalCurrency,
    Order_Id: orderDetails?.orderId,
    Order_ProductsAmount: orderDetails.totalProductPrice,
    Order_DiscountAmount: getOrderTotalDiscount(orderDetails),
    Order_ShippingAmount: orderDetails.totalShippingCharge,
    Order_ShippingDiscount: getOrderShippingDiscount(orderDetails),
    Order_QuotId: '',
    Order_ShippingDeliveryDate: '',
    Order_ShippingType: 'Home',
    Order_Type: 'Standard',
    Order_ShippingTaxRate: orderDetails.totalShippingTax,
    Order_Subtotal: orderDetails.totalProductPrice,
    Order_Total: orderDetails.grandTotal,
    Order_TaxAmount: orderDetails.totalSalesTax,
    Order_ShippingMode: orderDetails.orderItem[0].shipModeDescription,
    Order_PaymentInstallments: '',
    Order_PaymentType: getOrderPaymentType(data.order),
    Order_State: getOrderState(orderDetails),
    Order_ZipCode: getOrderZipCode(orderDetails),
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPaymentEvent = (data: {
  common: Partial<CommonData>
  products: IProduct[]
  partNumberQuantities: Map<string, number>
  algoliaPrices: PartNumberAlgoliaPrice
  email?: string
  isOptin?: boolean
  shipMode?: string
}): void => {
  const { products, partNumberQuantities, algoliaPrices, isOptin } = data
  const analyticsProducts = getProductsForAnalytics({
    products: products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
    partNumberQuantities,
    algoliaPrices,
  })
  const dataToSend: PaymentData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: ANALYTICS_PAGE_TYPE.PAYMENT,
    Page_Section1: 'Checkout',
    Page_Section2: isReorder() ? 'Reorder' : data.common.Page_Section2 ?? '',
    Order_ShippingMode: data.shipMode,
    Order_ShippingType: 'Home',
    User_EmailOptin: !!isOptin ? '1' : '0',
    Events_UserEmailSub: !!isOptin ? '1' : '0',
    Products: analyticsProducts,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendAccountInformationEvent = ({ common, Page_Type, Page_Section1 }) => {
  const dataToSend = {
    ...common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type,
    Page_Section1,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendProductUpdateEvent = (product): void => {
  const analyticsProducts = getProductsForAnalytics({ products: [product] })
  const dataToSend: ProductUpdateData = {
    id: 'Prods-Update',
    Products: analyticsProducts,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendHomeEvent = (common: Partial<CommonData>): void => {
  const dataToSend: DataToSend = {
    ...common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Home',
    Page_Section1: 'Home',
  }
  sendAnalyticEvent(dataToSend)
}

export const sendOrderSummaryData = (data: {
  common?: Partial<CommonData>
  products: IProduct[]
  partNumberQuantities: Map<string, number>
  algoliaPrices: PartNumberAlgoliaPrice
  email?: string
  order: IOrderSliceState
  shipMode?: string
}): void => {
  const { orderDetails } = data.order

  if (!orderDetails) {
    return
  }

  const analyticsProducts = getProductsForTYPAnalytics(
    data.products,
    orderDetails,
    data.partNumberQuantities,
    data.algoliaPrices
  )

  const dataToSend: OrderSummaryData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Products: analyticsProducts,
    User_EmailOptin: '0',
    Order_CartId: orderDetails?.orderId,
    Order_Currency: orderDetails.grandTotalCurrency,
    Order_Id: orderDetails?.orderId,
    Order_ProductsAmount: orderDetails.totalProductPrice,
    Order_DiscountAmount: getOrderTotalDiscount(orderDetails),
    Order_ShippingAmount: orderDetails.totalShippingCharge,
    Order_ShippingDiscount: getOrderShippingDiscount(orderDetails),
    Order_QuotId: '',
    Order_ShippingDeliveryDate: '',
    Order_ShippingType: 'Home',
    Order_Type: 'Standard',
    Order_ShippingTaxRate: orderDetails.totalShippingTax,
    Order_Subtotal: orderDetails.totalProductPrice,
    Order_Total: orderDetails.grandTotal,
    Order_TaxAmount: orderDetails.totalSalesTax,
    Order_ShippingMode: orderDetails.orderItem[0].shipModeDescription,
    Order_PaymentInstallments: '',
    Order_PaymentType: getOrderPaymentType(data.order),
    Order_State: getOrderState(orderDetails),
    Order_ZipCode: getOrderZipCode(orderDetails),
  }
  sendAnalyticEvent(dataToSend)
}

export const sendStaticEvent = (data: {
  common: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Static',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendLandingEvent = (data: {
  common: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Landing',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendSearchResultEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: IProduct[]
  pageSection: string
  searchKeyword: string
  searchKeyActual: string
}): void => {
  const analyticsProducts = getProductsForAnalytics({ products: data.products })
  const dataToSend: SearchPageData = {
    ...data.common,
    id: VIRTUAL_PAGE_VIEW_EVENT_ID,
    Page_Type: 'Search',
    Page_Section1: 'Search',
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Tiles&Pages',
    Products: analyticsProducts,
    Search_Keyword: data.searchKeyword,
    Search_KeyActual: data.searchKeyActual,
    Search_Type: 'text',
    Search_View: 'SERB',
    Events_SearchRun: '1',
  }
  sendAnalyticEvent(dataToSend)
}

export const sendSearchRunEvent = (data: {
  searchKeyword: string
  searchKeywordActual: string
  searchType: SearchRunType
  searchView: SearchRunView
  qnt: number
}): void => {
  const dataToSend: SearchRunPageData = {
    id: 'Event',
    Events_SearchRun: '1',
    Search_Keyword: data.searchKeyword,
    Search_KeyActual: data.searchKeywordActual,
    Search_Type: data.searchType,
    Search_View: data.searchView,
    Search_ResultItemsQnt: data.qnt,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendSearchFilteringEvent = (data: { facetValues: string; qnt: number }): void => {
  const dataToSend: SearchFilteringPageData = {
    id: 'Event',
    Events_SearchFiltering: '1',
    Search_ResultItemsQnt: data.qnt,
    Search_FacetValues_String: data.facetValues,
  }
  sendAnalyticEvent(dataToSend)
}

export const getPartNumberQuantities = (parsedOrderItems: OrderItem[]): Map<string, number> => {
  const partNumbersCount: Record<string, number> = parsedOrderItems.reduce(
    (productPartNumbersCountInOrder, product) => {
      return {
        ...productPartNumbersCountInOrder,
        [product.partNumber]:
          +product.quantity +
          +(product.sibilingOrderItem?.quantity || 0) +
          (productPartNumbersCountInOrder[product.partNumber] || 0),
      }
    },
    {}
  )
  const partNumbersCountMap = new Map<string, number>()
  for (const key of Object.keys(partNumbersCount)) {
    partNumbersCountMap.set(key, partNumbersCount[key])
  }
  return partNumbersCountMap
}

export const getFrameAdvisorAnalyticsData = (frameGeniusData?: FrameGeniusType) => {
  let formattedData = ''
  frameGeniusData &&
    Object.values(frameGeniusData.analysisResults).map((v, i) => {
      if (typeof v === 'object' && !Array.isArray(v)) return

      return (formattedData += `${Object.keys(frameGeniusData.analysisResults)[i]}=${v},`)
    })

  return {
    Page_UserFace: formattedData, // FaceShape=, FaceLength=, Age=, HairColor=, EyeColor= E.g. "FaceShape=Angular,FaceLength=Oval
    Page_UserStyle: '',
  }
}

export const sendFilterSelectedEvent = (
  filters: {
    [key: string]: { value: string; facetName: string }
  },
  count?: number,
  frameGeniusData?: FrameGeniusType
): void => {
  Log.info('ANALYTICS - send filter selected event: ' + (filters as any))
  const isFrameGeniusToggleEnabled = SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  const frameAdvisorData = getFrameAdvisorAnalyticsData(frameGeniusData)

  let facetsValue = ''

  Object.values(filters).forEach((v, i, array) => {
    if (v.value) {
      facetsValue += `${v.facetName}=${v.value}`
      if (i !== array.length - 1) {
        facetsValue += '|'
      }
    }
  })

  let dataToSend = {
    ...frameAdvisorData,
    id: 'SearchFilterUpdated',
    Search_FacetValues_String: facetsValue,
    Search_ResultItemsQnt: count?.toString(),
    Events_SearchFiltering: '1',
    Search_FacetValues_Type: !!isFrameGeniusToggleEnabled ? 'framegenius' : '', // standard, framegenius
  }

  sendAnalyticEvent(dataToSend)
}

export const sendNewsletterSubscriptionEvent = (email: string): void => {
  const formattedEmail = email?.toLowerCase()
  Log.info('ANALYTICS - send newsletter subscription event: ' + email)

  const md5Email = md5(formattedEmail).toString()
  const shaEmail = sha256(formattedEmail).toString()

  const dataToSend = {
    id: TRACKER_NEWSLETTER_SIGNUP,
    User_Email_MD5: md5Email,
    User_Email_SHA256: shaEmail,
    Events_UserEmailSub: '1',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendRegistrationEvent = (
  email: string,
  userId: string,
  userCountry: string,
  hasPrescription: string,
  acceptedNewsletterFlag?: boolean
): void => {
  const formattedEmail = email?.toLowerCase()
  const md5Email = md5(formattedEmail).toString()
  const shaEmail = sha256(formattedEmail).toString()
  const segmentsFromCookie = getCustomerSegmentsUtil()?.join(', ')

  Log.info('ANALYTICS - send registration event: ' + formattedEmail)
  let dataToSend = {
    id: 'Event',
    User_Id: userId,
    User_LoginStatus: USER_SEGMENT_LOGGED,
    User_LoginType: 'Standard',
    User_Email_MD5: md5Email,
    User_Email_SHA256: shaEmail,
    Events_UserEmailSub: Boolean(acceptedNewsletterFlag) ? '1' : '0',
    Events_UserAccountNew: '1',
    User_LoyaltyCard: '',
    User_LoyaltyTier: '',
    User_Phone_MD5: '',
    User_Phone_SHA256: '',
    User_Segments: segmentsFromCookie,
    User_Country: userCountry?.toUpperCase() ?? '',
    User_Nation: userCountry?.toUpperCase() ?? '',
    User_HasPrescription: hasPrescription,
  }

  sendAnalyticEventRaw(dataToSend)
}

export const sendSearchEvent = (searchingString: string, qty: string): void => {
  Log.info('ANALYTICS - searching event: ' + searchingString)
  let dataToSend = {
    id: TRACKER_SEARCH,
    Search_ResultItemsQnt: qty,
    Search_Keyword: searchingString.toLowerCase(),
    Search_Type: 'text',
    Search_View: 'SERB',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPromoCodeEvent = (isPromoCodeAccordionOpen: boolean): void => {
  Log.info('ANALYTICS - promoCode event')
  let dataToSend = {
    id: TRACKER_PROMOCODE_TOGGLE,
  }
  // Send event only for promo code accordion open action, i.e. when current state is closed.
  !isPromoCodeAccordionOpen && sendAnalyticEvent(dataToSend)
}

export const sendMiniCartOverlayOpenEvent = () => {
  sendAnalyticEvent({
    id: TRACKER_MINICART_OVERLAY_OPEN,
  })
}

export const sendInsuranceEvent = (payload: InsuranceEventPayload) => {
  sendAnalyticEvent(payload)
}

export const isReorder = () => sessionStorageUtil.get(ANALYTICS_IS_REORDER) === true

export const formatElementString = (key: string) => key?.replace(' ', '_')

export const sendButtonOnClickEvent = (data_element_id?: string, data_description?: string): void => {
  sendAnalyticEvent({
    id: TRACKER_MOUSE_CLICK,
    data_element_id,
    data_description,
  })
}
