import { RootReducerState } from '@redux/rootReducer'
import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { withCredentialsEnabled } from '@utils/helpers'

export interface IAvailabilityVMArgs {
  upc: string
}

export interface IAvailabilityVMRawResponse {
  [key: string]: {
    available: boolean
    lods: never[]
    resolutions: string[]
    transitions: boolean
    transitionsResolutions: string[]
  }
}

export interface IAvailabilityVMResponse {
  available: boolean
}

export const vmApi = createApi({
  reducerPath: 'vmApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: builder => ({
    getAvailability: builder.query<IAvailabilityVMResponse, IAvailabilityVMArgs>({
      async queryFn(args, queryApi, _extraOptions, baseQuery) {
        const { upc } = args
        const state = queryApi.getState() as RootReducerState
        const VM_AVAILABILITY_SERVER =
          state.site.currentSite?.xStoreCfg.VM_AVAILABILITY_SERVER || 'https://vmcore.luxottica.com'

        const result = await baseQuery({
          url: `${VM_AVAILABILITY_SERVER}/api/upc/v2/${upc}/availability`,
          method: 'get',
        })

        return result.data
          ? {
            data: transformResponse(result.data as IAvailabilityVMRawResponse, upc),
          }
          : { error: result[0].error as FetchBaseQueryError }
      },
    }),
  }),
})

const transformResponse = (resultData: IAvailabilityVMRawResponse, upc: string) => {
  return {
    available: resultData[upc].available,
  }
}

export const { useGetAvailabilityQuery } = vmApi
