import Axios from 'axios'

import { SiteInfo } from '@redux/rootReducer'
//Standard libraries
import { SKIP_WC_TOKEN_HEADER } from '../../constants/common'
import config from '../../../configs'
import { logger } from '../../../logging'
import { isBrowser } from '@utils/isBrowser'
declare module 'axios' {
  export interface AxiosRequestConfig {
    [SKIP_WC_TOKEN_HEADER]?: boolean
  }
}
export interface SiteInfoArgs {
  storeName?: string
  searchContext: string
  transactionContext?: string
  transactionContextUrl?: string
  storeID?: string
  [extraPros: string]: any
}

export class SiteInfoService {
  private static mySiteInfo: SiteInfoService = new SiteInfoService()
  private readonly B2B = 'B2B'
  private readonly BMH = 'BMH'
  private siteInfo: SiteInfo | null = null

  public static getSiteInfo(): SiteInfoService {
    return SiteInfoService.mySiteInfo
  }

  public getSiteValue(): SiteInfo | null {
    return this.siteInfo
  }

  public setData(s: SiteInfo) {
    this.siteInfo = s
  }

  public async setSite(lang: string): Promise<SiteInfo> {
    const locale = lang?.toLowerCase().replace('_', '-')
    const url = `${isBrowser() ? config.cmsApiUrl : config.cmsApiUrlInternal}/wcs/storeinfo/${
      config.storePrefix
    }/${locale}`
    logger.info(`init site data with locale: ${locale}, url: ${url}`)

    const res = await Axios.get(url, {
      [SKIP_WC_TOKEN_HEADER]: true,
    })

    const [lng, langCode]: string[] = res.data.locale.split('-')

    const site: SiteInfo = {
      ...res.data,
      storeID: res.data.storeId,
      defaultLanguageID: res.data.defaultLanguageId,
      locale: `${lng}_${langCode}`,
      xStoreCfg: {
        ...res.data.xStoreCfg,
        frameGenius: {
          isFrameAdvisorEnabled: res.data.xStoreCfg['FrameAdvisor.enabled'] || false,
          scriptUrl: res.data.xStoreCfg['FrameAdvisor.UI.core'] || false,
          isSizeAdvisorEnabled: res.data.xStoreCfg['sizeAdvisorEnabled'] || false,
        },
      },
    }
    this.siteInfo = site
    return site
  }
}
