import { IProduct, IProductAttributes, ServerProductAttribute } from '../types/product'
import { get, pickBy, split } from 'lodash-es'

import { PRODUCT_TYPES_MAP } from '../constants/product'
import { TFunction } from 'next-i18next'
import { IBadges } from '@redux/reducers/reducerStateInterface'
import Log from '@services/Log'
import { OrderItem, OrderUnavailableItem } from '@typesApp/order'

export type Product = Pick<IProduct, 'productAttributes' | 'cluster' | 'attributes'>

export const PRODUCT_ATTRIBUTE_TRUE_VALUE = true

export const getProductAttribute = (product?: Product | OrderUnavailableItem | null, key?: string, subKey?: string) => {
  try {
    const attributes = product?.productAttributes ?? product?.attributes ?? []
    let attribute = attributes[key ?? '']
    if (attribute === false) return attribute
    if (!attribute) {
      const clusters = product?.cluster
      if (clusters && clusters.length) {
        const cluster = clusters[0]
        const clusterAttributes = cluster?.productAttributes || []
        attribute = clusterAttributes[key ?? '']
      } else {
        // Fallback to old data structure for cart
        const legacyAttribute = product?.attributes?.find(attr => attr.identifier === key)
        attribute = get(legacyAttribute, 'values[0].value', '')
      }
    }
    if (subKey) {
      const item = attribute?.values[subKey ?? '']
      return item ?? ''
    } else {
      return attribute ?? ''
    }
  } catch (e) {
    Log.error(`Could not retrieve attribute details: ${e}`)
    return ''
  }
}

export const getNaturalAttribute = (product?: Product | OrderUnavailableItem | null, key?: string) => {
  try {
    let attribute = ''
    const legacyAttribute = product?.attributes?.find(attr => attr.identifier === key)
    attribute = get(legacyAttribute, 'attribute.natural', '')

    return attribute ?? ''
  } catch (e) {
    Log.error(`Could not retrieve attribute details: ${e}`)
    return ''
  }
}

export const getFrameShape = (p: Product) => getProductAttribute(p, 'FRAME_SHAPE')
export const getFrameType = (p: Product) => getProductAttribute(p, 'FRAME_TYPE')
export const getFrameMaterial = (p: Product) => getProductAttribute(p, 'FRAME_MATERIAL_CLASS')
export const getFrameMaterialFacet = (p: Product) => getProductAttribute(p, 'FRAME_MATERIAL_FACET')
export const getTempleColorFacet = (p: Product) => getProductAttribute(p, 'TEMPLE_COLOR_FACET')
export const getFrontColorFacet = (p: Product) => getProductAttribute(p, 'TEMPLE_COLOR_FACET')
export const getColorCode = (p: Product) => getProductAttribute(p, 'COLOR_CODE')
export const getIsPolarized = (p: Product | IProduct) => getProductAttribute(p, 'POLARIZED')
export const getIsGeoFit = (p: IProduct) => getProductAttribute(p, 'GEOFIT')
export const getIsPhotochromic = (p: IProduct) => getProductAttribute(p, 'PHOTOCHROMIC')
export const getIsMostPopular = (p: Product) => getProductAttribute(p, 'LX_IS_MOST_POPULAR')
export const getIsChildFrame = p => getProductAttribute(p, 'GENDER') === PRODUCT_ATTRIBUTE_CHILD_VALUE
export const getIsRoxable = (p: Product) => Boolean(getProductAttribute(p, 'ROXABLE')) === PRODUCT_ATTRIBUTE_TRUE_VALUE
export const getIsSustainable = (p: Product) => getProductAttribute(p, 'SUSTAINABILITY_CLAIM')
export const getIsCustomizable = (p: Product) => getProductAttribute(p, 'CUSTOMIZABLE')
export const getIsOnlineExclusive = (p: Product) => getProductAttribute(p, 'WEB EXCLUSIVE')
export const getIsExclusive = (p: Product) => getProductAttribute(p, 'EXCLUSIVE')
export const getIsAvantPremiere = (p: Product) => getProductAttribute(p, 'AVANT_PREMIERE')
export const getIsLimitedEdition = (p: Product) => getProductAttribute(p, 'LIMITED_EDITION')
export const getAnnualSupplyBadge = (p: Product) => getProductAttribute(p, 'CL_ANNUAL_SUPPLY_BADGES')
export const getSupplyDuration = (p: Product) => getProductAttribute(p, 'CL_SUPPLY_DURATION')
export const getNew = (p: Product) => getProductAttribute(p, 'IS_NEW')
export const getBadge = (p: Product) => getProductAttribute(p, 'BADGE')
export const getSoldOut = (p: Product) => getProductAttribute(p, 'LX_SOLDOUT')
export const getAlreadyDisplayed = (p: Product) => getProductAttribute(p, 'LX_ALREADY_DISPLAYED')
export const getLifeCycle = (p: Product) => getProductAttribute(p, 'LIFECYCLE')
export const getClPackSize = (p: Product) => getProductAttribute(p, 'CL_PACK_SIZE')
export const getClVolume = (p: Product) => getProductAttribute(p, 'CL_VOLUME')
export const getClAdd = (p: Product) => getProductAttribute(p, 'CL_ADD')
export const getClAddition = (p: Product) => getProductAttribute(p, 'CL_ADDITION')
export const getClAdditionRange = (p: Product) => getProductAttribute(p, 'CL_ADDITION_RANGE')
export const getClSpherePower = (p: IProduct | OrderItem) => p.productAttributes?.['CL_SPHERE_POWER'] as string
export const getClAxis = (p: Product) => getProductAttribute(p, 'CL_AXIS')
export const getClAxisRange = (p: Product) => getProductAttribute(p, 'CL_AXIS_RANGE')
export const getClColorRange = (p: Product) => getProductAttribute(p, 'CL_COLOR_RANGE')
export const getClCyl = (p: Product) => getProductAttribute(p, 'CL_CYL')
export const getClCylinder = (p: Product) => getProductAttribute(p, 'CL_CYLINDER')
export const getClCylinderRange = (p: Product) => getProductAttribute(p, 'CL_CYLINDER_RANGE')
export const getClDominance = (p: Product) => getProductAttribute(p, 'CL_DOMINANCE')
export const getModelCodeDisplay = (p?: Product) => getProductAttribute(p, 'MODEL_CODE_DISPLAY')
export const getFrontColor = (p?: Product) => getProductAttribute(p, 'FRONT_COLOR')
export const getLensesColor = (p?: Product | null) => getProductAttribute(p, 'LENS_COLOR')
export const getLensesColorFacet = (p?: Product) => getProductAttribute(p, 'LENS_COLOR_FACET')
export const getTempleColor = (p?: Product) => getProductAttribute(p, 'TEMPLE_COLOR')
export const getLensesTreatment = (p: Product) => getProductAttribute(p, 'LENS_TREATMENT_FACET')
export const getLensMacroMaterial = (p: Product) => getProductAttribute(p, 'LENS_MATERIAL_MACRO_CLASS')
export const getLensCorrectionType = (p: Product) => getProductAttribute(p, 'CORRECTION_TYPE')
export const getLensThickness = (p: Product) => getProductAttribute(p, 'LENS_THICKNESS')
export const getDrivingASNZS = (p: Product) => getProductAttribute(p, 'DRIVING_ASNZS')

export const getCaseImageUrl = (p: Product) => getProductAttribute(p, 'INCLUDED_ITEMS', 'INCLUDED_ITEMS_IMAGE_URL')

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'
/** Returns formatted product size if TFunction from react-i18n was passed as argument.
 * Otherwise returns value of FRAME_SIZE_DISPLAY attribute */
export const getSize = (p: Product, t?): string => {
  const frameSizeDisplay = getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && frameSizeDisplay ? t(productSizeNls) : frameSizeDisplay
}

export const getAntiBlue = (p: IProduct) => getProductAttribute(p, 'ANTI_BLUE')
export const getCorrectionType = (p: IProduct) => getProductAttribute(p, 'CORRECTION_TYPE')
export const getLensTreatment = (p: IProduct) => getProductAttribute(p, 'LENS_TREATMENT')
export const getTransitionType = (p: IProduct) => getProductAttribute(p, 'TRANSITION_TYPE')
export const getClLensRange = (p: IProduct) => getProductAttribute(p, 'CL_LENS_RANGE')
export const getHingeDistance = (p: Product) => getProductAttribute(p, 'HINGE_DISTANCE')
export const getMostSoldSize = (p: Product) => getProductAttribute(p, 'MOST_SOLD_SIZE')

export const getFrameSize = (p: Product) => getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
export const getFrame = (p: Product) => getProductAttribute(p, 'FRAME_SIZE')

export const getBrand = (p: Product) => getProductAttribute(p, 'BRAND')
export const getCLBrand = (p: Product | OrderUnavailableItem) => getProductAttribute(p, 'CL_BRAND')

export const getModelName = (p: Product | OrderUnavailableItem) => getProductAttribute(p, 'MODEL_NAME')
export const getProductType = (p?: Product) => getProductAttribute(p, 'PRODUCT_TYPE')

export const getNormalizedProductType = (p?: IProduct | Product) => {
  const pt: string = getProductType(p)
  const res = pt && PRODUCT_TYPES_MAP[pt.toLowerCase()]
  return res ?? 'frames'
}

export const getSearchPageProductType = (p?: Product) => getProductAttribute(p, 'LX_SEARCH_PAGE_PRODUCT_TYPE')
export const getModelCode = (p: Product | OrderUnavailableItem) => getProductAttribute(p, 'DISPLAYSKU')

export const getBridgeWidth = (p: Product) => getProductAttribute(p, 'BRIDGE_WIDTH')
export const getLensWidth = (p: Product) => getProductAttribute(p, 'LENS_WIDTH')
export const getTempleLength = (p: Product) => getProductAttribute(p, 'TEMPLE_LENGTH')
export const getCanonicalCategory = (p?: Product) => {
  return getProductAttribute(p, 'LX_CANONICAL_CATEGORY')
}

export const getProductColorFacet = (facetsMap, key: string) => {
  let facetInfo = facetsMap.filter(facet => {
    return key.includes(facet.key)
  })
  return facetInfo[0] || null
}

export const getAllProductAttributes = (attributes: ServerProductAttribute[] = []): IProductAttributes => {
  return attributes.reduce((acc: IProductAttributes, attr: ServerProductAttribute) => {
    const value = get(attr, 'values[0].value', '')
    return {
      ...acc,
      [attr.identifier]: value,
    }
  }, {})
}

// @TODO TEMP TO USE UNTIL WE HAVE IMAGES

type ProductImageKind =
  | 'quarterShad'
  | 'frontShad'
  | 'lateralShad'
  | 'closedFrontShad'
  | 'backShad'
  | 'alternativeOneShad'
  | 'alternativeTwoShad'
  | 'alternativeNShad'
  | 'foldingShad'
  | 'foldingGroupShad'

const productImageKindMap: { [key: string]: string } = {
  quarterShad: '030A',
  quarterNoShad: '000A',
  frontShad: '000A',
  lateralShad: '090A',
  closedFrontShad: 'cfr',
  closedFrontNoShad: 'crf',
  backShad: '180A',
  alternativeOneShad: 'al1',
  alternativeTwoShad: 'al2',
  alternativeNShad: 'alN',
  foldingShad: 'fld',
  foldingGroupShad: 'fldg',
  groupNoShad: 'grp',
  onModelNoShad: 'om',
  adv: 'adv',
  advn: 'advN',
}

export const getTempImgUrlImageUrl = (partNumber: string, width: number, kind: ProductImageKind): string => {
  // final string url example
  // https://my4c-im.luxottica.com/6S/06S2002/06S2002__200206_030A.png?imwidth=600
  const { productCode, moco, brand } = getProductInfo(partNumber)
  const baseUrl = new URL('https://my4c-im.luxottica.com')
  const brandPartialUrl = `/${brand}`
  const productPartialUrl = `/${productCode}`
  const productImageName = getProductImageName(productCode, moco, kind)
  const completeUrl = new URL(`${brandPartialUrl}${productPartialUrl}${productImageName}`, baseUrl)
  return `${completeUrl.toString()}?imwidth=${width}`
}

export const getProductInfo = partNumber => {
  const [productCode, moco] = split(partNumber, '__', 2)
  const brand = productCode.substr(1, 2)
  return { productCode, moco, brand }
}

export const getProductImageName = (productCode: string, moco: string, kind: ProductImageKind) => {
  let baseName = `/${productCode}__${moco}`
  const kindKey = productImageKindMap[kind] || ''
  return `${baseName}_${kindKey}.png`
}

export const PRODUCT_ATTRIBUTE_CHILD_VALUE = 'Child' as const
/* PHOTOCROMIC is included as a mis-spelling that was indexed in Algolia */
export const PRODUCT_ATTRIBUTE_LIGHT_ADAPTIVE_VALUE = ['PHOTOCHROMIC', 'PHOTOCROMIC', 'TRANSITIONS®']
export const PRODUCT_ATTRIBUTE_BLUE_LIGHT_VALUE = ['BLUE LIGHT']

export interface ProductBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isMostPopular: boolean
  isRoxable: boolean
  isKids: boolean
}

const getPriorityBadgeText = (badges: ProductBadges, t: TFunction): string => {
  switch (true) {
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}
export const getBadges = (
  product,
  t: TFunction,
  plpBadges: IBadges = {} as IBadges,
  isRXEnabled = false
): {
  primaryBadge: string
  secondaryBadges: string
} => {
  const badges: ProductBadges = {
    isBadge: plpBadges.BADGE === getBadge(product),
    isExclusive: plpBadges.EXCLUSIVE === getIsExclusive(product),
    isOnlineExclusive: plpBadges['WEB EXCLUSIVE'] === getIsOnlineExclusive(product),
    isAvantPremiere: plpBadges.AVANT_PREMIERE === getIsAvantPremiere(product),
    isNew: plpBadges.IS_NEW === getNew(product),
    isMostPopular: plpBadges.LX_IS_MOST_POPULAR === getIsMostPopular(product),
    isKids: getIsChildFrame(product),
    isLimitedEdition: plpBadges.LIMITED_EDITION === getIsLimitedEdition(product),
    isRoxable: isRXEnabled && getIsRoxable(product) && Boolean(plpBadges.ROXABLE) === PRODUCT_ATTRIBUTE_TRUE_VALUE,
    isCustomizable: plpBadges.CUSTOMIZABLE === getIsCustomizable(product),
    isPolarized: plpBadges.POLARIZED === getIsPolarized(product),
    isSustainable: plpBadges.SUSTAINABILITY_CLAIM === getIsSustainable(product),
  }

  const primaryBadge = getPriorityBadgeText(badges, t)

  const currentLensTreatment = getLensesTreatment(product)
  // const currentLensTreatmentFacet = getLensTreatmentFacet(product)
  const activeBadges = Object.keys(
    pickBy(
      {
        Kids: badges.isKids,
        Polarised: badges.isPolarized,
        LightAdaptive: PRODUCT_ATTRIBUTE_LIGHT_ADAPTIVE_VALUE.includes(currentLensTreatment.toUpperCase()),
        Sustainable: badges.isSustainable,
        BlueLightFilterIncluded: PRODUCT_ATTRIBUTE_BLUE_LIGHT_VALUE.includes(currentLensTreatment.toUpperCase()),
      },
      cb => cb
    )
  )

  const secondaryBadges = (activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges)
    .map(activeBadge => t(`ProductTile.ActiveBadges.${activeBadge}`))
    .join(' | ')

  return { primaryBadge, secondaryBadges }
}
// END TEMP
