import config from '@configs/index'
export const site = {
  storeName: config?.name || '',
  country: config?.defaultCountry || '',
  transactionContextUrl: `${config?.transactionHost}${config?.transactionContext}` || '',
  transactionContext: config?.transactionContext || '',
  searchContext: config?.searchContext || '',
  searchContextUrl: `${config?.transactionHost}${config?.searchContext}` || '',
  searchContextUrlInternal: `${config?.transactionHostInternal}${config?.searchContext}` || '',
  cmcPath: '/lobtools/cmc/ManagementCenterMain',
}

export const STORES = {
  'en-ca': {
    storeId: '70201',
    langId: '-24',
    country: 'ca',
    catalogId: '70201',
  },
  'en-au': {
    storeId: '70202',
    langId: '-47',
    country: 'au',
    catalogId: '70202',
  },
  'en-nz': {
    storeId: '70203',
    langId: '-54',
    country: 'nz',
    catalogId: '70202',
  },
  'fr-ca': {
    storeId: '70201',
    langId: '-25',
    country: 'ca',
    catalogId: '70201',
  },
  'en-ww': {
    storeId: '70999',
    langId: '-99',
    country: 'ww',
    catalogId: '70999',
  },
}
