// TODO: requested that this validation happens in the BE so we dont need to do it here (second phase changes)
export const RESTRICTED_INTERVALS = {
  WEE: ['1', '6', '12'],
  MON: ['12'],
}

export const SUBSCRIPTION_RECURRENCY_STRING = {
  SEPARATOR: '|',
  VALUE_INDEX: 0,
  RECURRENCY_INDEX: 1,
}

export const SUBSCRIPTION_TERMS_AND_CONDITIONS_ESPOT = 'SubscriptionDiscountTermsAndCondition'
export const SUBSCRIPTION_DISCOUNT_ADJUSTMENTS = {
  ITEM: 'SUBSCRIPTION_PROMO',
  SHIPPING: 'SUBSCRIPTION_SHIPPING_PROMO',
}
export const SUBSCRIPTION_ERROR_KEYS = [
  '_ERR_CART_IS_NOT_EMPTY_TO_ADD_SUBSCRIBABLE_ITEMS',
  '_ERR_EXISTING_ITEM_WITH_SUBSCRIPTION_IN_PENDING_CART',
  '_ERR_X_PRODUCTID_CONTACT_LENS_NOT_PRESENT_OR_INCORRECT',
  'Subscription Not Supported with MTO ',
]
export const SUBSCRIPTION_FALLBACK_RECURRENCY = '1|MON'
//todo remove after we have working APIs
export const SUBSCRIPTION_DISCOUNT_PERCENT_STRING = '20%'
export const SUBSCRIPTION_DISCOUNT_PERCENT_VALUE = 0.2

export const SUBSCRIPTION_STATES = {
  Active: 'Active',
  Suspended: 'Suspended',
  InActive: 'InActive',
  Cancelled: 'Cancelled',
  PendingCancel: 'PendingCancel',
  Paused: 'Paused',
}

export const WALLET_SUBSCRIPTION_STATUS_FIELD = 'subscriptionStatus'

export const SUBSCRIPTION_STATUS_CODES = {
  PAUSED: '0',
  ACTIVE: '1',
  CANCELLED: '3',
}

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'
export const CALENDAR_DATE_FORMAT = 'DD/MM/YYYY'

export const SUBSCRIPTION_ERROR_CODES = {
  INVALID_CART: 'Subscription - ineligible items in cart',
  CARD_EXPIRED: 'Subscription - card expired',
  INVALID_PROMO_CODE: '-1160',
}

export const ALLOWED_NEWSLETTER_SUBSCRIPTION_BY_COUNTRY: string[] = ['ca']
