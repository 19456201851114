import { IOrderDetails, OrderItem } from '@typesApp/order'
import { ProductForAnalytics, ProductForAnalyticsFields } from '../interfaces'
import {
  getBrand,
  getFrameMaterial,
  getFrameShape,
  getFrameType,
  getFrontColor,
  getLensesColor,
  getLensesTreatment,
  getModelName,
  getProductType,
  getSoldOut,
} from '../../../../utils/productAttributes'

import { ProductAnalyticsRX, ProductSoldOutStatus, IProduct } from '../../../../types/product'
import { get } from 'lodash-es'
import { getProductPrices, padDecimals } from '../../../../utils/productPrice'
import { getRxPrice } from '../../../../utils/isRxOrder'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { PRODUCT_OUT_OF_STOCK_OPTIONS, PRODUCT_STATUS, PRODUCT_STATUS_FOR_ANALYTICS } from '../constants/tracker'
import { AlgoliaPrice, PartNumberAlgoliaPrice } from '../../../algolia/algoliaPrice'
import {
  ADJUSTMENT_DISCOUNT,
  ANALYTICS_PRODUCT_CATEGORY_MAP,
  SHIPPING_ADJUSTMENT_TEXT,
} from '../../../../constants/common'
import { getLensType } from '@utils/productAttributesAlgolia'
import { IOrderSliceState } from '@features/order/IOrderSliceState'
import { CHECKOUT_NAMES } from '@constants/paymentMethods'

export const LENS_TYPE = {
  RX: 'RX',
  PLANO: 'PLANO',
  STD: 'STD',
}

export const parseCatentriesForRX = (
  orderItems: OrderItem[],
  products: IProduct[],
  algoliaPrices: PartNumberAlgoliaPrice
): ProductAnalyticsRX[] => {
  // If there's an RX product, removes from the catEntries the lens and services objects (that are provided as a normal item)
  // Then, add to the single RX item (the frame), the total RX price.
  let FRAME_CATENTRIES: IProduct[] = []
  const filteredVal = orderItems?.filter(i => !!i?.roxableServices?.length)!
  const emptyVal = orderItems?.filter(i => !i?.roxableServices?.length)!
  emptyVal.forEach(item => {
    const FRAME_ID = item?.productId
    const FRAME_CATENTRY: any = products.find(f => f.id === FRAME_ID)
    if (FRAME_CATENTRY) {
      FRAME_CATENTRIES.push(FRAME_CATENTRY)
    }
  })
  let allServiceId: string[] = []
  filteredVal.forEach(RX_FRAME => {
    const FRAME_ID = RX_FRAME?.productId
    const SERVICES_IDS: string[] =
      (RX_FRAME && RX_FRAME!.roxableServices && (RX_FRAME!.roxableServices!.map(s => s.productId) as string[])) || []

    allServiceId = [...allServiceId, ...SERVICES_IDS]

    const FRAME_CATENTRY: any = products.find(f => f.id === FRAME_ID)

    const RX_PRICE =
      RX_FRAME &&
      RX_FRAME.roxableServices &&
      FRAME_CATENTRY &&
      algoliaPrices &&
      getRxPrice(
        RX_FRAME.roxableServices,
        `${algoliaPrices[FRAME_CATENTRY.partNumber]?.listPrice}` || `${FRAME_CATENTRY?.rxPrice}`
      )
    const RX_OFFER_PRICE =
      RX_FRAME &&
      FRAME_CATENTRY &&
      algoliaPrices &&
      getRxPrice(
        RX_FRAME.roxableServices!,
        `${algoliaPrices[FRAME_CATENTRY.partNumber]?.offerPrice}` || `${FRAME_CATENTRY?.x_offerpriceRx}`
      )
    FRAME_CATENTRIES.push({
      ...FRAME_CATENTRY,
      rxPrice: RX_PRICE,
      rxOfferPrice: RX_OFFER_PRICE,
    })
  })
  FRAME_CATENTRIES = FRAME_CATENTRIES.filter(f => f.id && !allServiceId?.includes(f.id)) as ProductAnalyticsRX[]
  return FRAME_CATENTRIES
}

/**
 * Get the product status and out of stock option if any
 * @param product the product item
 * @param soldOutStatus defined sold out status
 * @param hasRecommendations a flag indicating if the product has algolia recommendations
 * @returns and object with Status and OosOptions properties
 */
export const getproductStatuswithOptionsForAnalytics = (product: IProduct, hasRecommendations?: boolean) => {
  const productStatus = getProductStatus(product)
  const status = PRODUCT_STATUS_FOR_ANALYTICS[productStatus]
  let oOsOptions = PRODUCT_OUT_OF_STOCK_OPTIONS.NONE

  if (status === PRODUCT_STATUS_FOR_ANALYTICS.SoldOut) {
    oOsOptions = hasRecommendations ? PRODUCT_OUT_OF_STOCK_OPTIONS.SEARCH_SIMILAR : PRODUCT_OUT_OF_STOCK_OPTIONS.NONE
  } else if (
    status === PRODUCT_STATUS_FOR_ANALYTICS['Coming soon'] ||
    status === PRODUCT_STATUS_FOR_ANALYTICS['Coming back soon']
  ) {
    oOsOptions = PRODUCT_OUT_OF_STOCK_OPTIONS.EMAIL_ME

    if (hasRecommendations) {
      oOsOptions += `,${PRODUCT_OUT_OF_STOCK_OPTIONS.SEARCH_SIMILAR}`
    }
  }

  return {
    Status: status,
    OosOptions: oOsOptions,
  }
}

const CUSTOM_FRAME_TYPE = 'CP'
export const formatProduct = (productInfo: IFormatProductProps): ProductForAnalyticsFields => {
  const { item: product, quantity, algoliaPrice, hasRecommendations } = productInfo
  const type = getProductType(product)?.toUpperCase() || ''
  const prices = getProductPrices(product)
  const productCategory = ANALYTICS_PRODUCT_CATEGORY_MAP[type] || type
  const { Status, OosOptions } = getproductStatuswithOptionsForAnalytics(product, hasRecommendations)

  let productContext: ProductForAnalyticsFields = {
    Case: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status,
    OosOptions,
    Category: productCategory,
    Type: product.rxPrice ? LENS_TYPE.RX : LENS_TYPE.STD,
    LensType:
      !product.rxPrice || getLensType(product).toLowerCase() === 'non_prescription' ? LENS_TYPE.PLANO : LENS_TYPE.RX,
    Price:
      algoliaPrice && productCategory.toLowerCase() !== PRODUCT_TYPES_KEYS.OPTICAL
        ? padDecimals(algoliaPrice?.offerPrice ?? algoliaPrice?.listPrice ?? '0')
        : product.rxOfferPrice
          ? padDecimals(product.rxOfferPrice)
          : prices?.discount || '0',
    PriceFull:
      algoliaPrice && productCategory.toLowerCase() !== PRODUCT_TYPES_KEYS.OPTICAL
        ? padDecimals(algoliaPrice?.listPrice ?? '0')
        : product.rxPrice
          ? padDecimals(product.rxPrice)
          : prices?.full || '0',
    Brand: getBrand(product),
    Sku: `${product.id}`,
    ModelName: getModelName(product),
    MoCo: `${product.name}`,
    LensColor: getLensesColor(product),
    LensTechnology: getLensesTreatment(product),
    FrameColor: getFrontColor(product),
    FrameTechnology: getFrameMaterial(product),
    Shape: getFrameShape(product),
    LensUPC: '',
    Units: quantity?.toString() || '1',
    FrameType: !!getFrameType(product) ? CUSTOM_FRAME_TYPE : undefined,
    InsuranceCode: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    Engraving: '',
    Url: window.location.origin + product?.seo?.href,
  }

  return productContext
}

export const getProductStatus = (product: IProduct, soldOutStatus?): string => {
  const soldOutLabel = soldOutStatus || getSoldOut(product)
  switch (soldOutLabel?.toUpperCase()) {
    case '':
    case PRODUCT_SOLDOUT_STATUS.NONE: {
      return PRODUCT_STATUS.AVAILABLE
    }
    case PRODUCT_SOLDOUT_STATUS.SOLDOUT: {
      return PRODUCT_STATUS.SOLD_OUT
    }
    case PRODUCT_SOLDOUT_STATUS.COMING_SOON: {
      return PRODUCT_STATUS.COMING_SOON
    }
    case PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK: {
      return PRODUCT_STATUS.OUT_OF_STOCK
    }
    case PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON: {
      return PRODUCT_STATUS.COMING_BACK_SOON
    }
    default:
      return PRODUCT_STATUS.AVAILABLE
  }
}

export const formatProductForTYP = (orderItem: OrderItem): Partial<ProductForAnalyticsFields> => ({
  TaxRate: orderItem.salesTax,
})

export interface IProductsForAnalyticsProps {
  products: ProductAnalyticsRX[]
  soldOutStatus?: ProductSoldOutStatus
  partNumberQuantities?: Map<string, number>
  algoliaPrices?: PartNumberAlgoliaPrice
  clAccessoriesWithError?: string[]
  hasRecommendations?: boolean
}

export interface IFormatProductProps {
  item: ProductAnalyticsRX
  quantity?: number
  algoliaPrice?: AlgoliaPrice
  soldOutStatus?: string
  hasRecommendations?: boolean
}

export const getProductsForAnalytics = (props: IProductsForAnalyticsProps): ProductForAnalytics => {
  const { clAccessoriesWithError, products, soldOutStatus, partNumberQuantities, algoliaPrices, hasRecommendations } =
    props
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    if (p.partNumber) {
      const isOutOfStock = clAccessoriesWithError && !!clAccessoriesWithError?.includes(p.partNumber)

      acc[p.partNumber] = {
        ...formatProduct({
          item: p,
          quantity: partNumberQuantities?.get(p.partNumber),
          algoliaPrice: algoliaPrices?.[p.partNumber || ''],
          soldOutStatus: isOutOfStock ? PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK : soldOutStatus,
          hasRecommendations,
        }),
      }
    }
    return acc
  }, {})
}

/** Products formatter for cart-related analytics events.
 * @returns map with unique `string` partNumber as key and `ProductForAnalyticsFields` as value
 */
export const getProductsInCartForAnalytics = (
  products: IProduct[],
  productPartNumbersCountInOrderMap: Map<string, number>,
  algoliaPrices: PartNumberAlgoliaPrice
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, product) => {
    if (product && product.partNumber) {
      acc[product.partNumber] = {
        ...formatProduct({
          item: product,
          quantity: productPartNumbersCountInOrderMap.get(product.partNumber),
          algoliaPrice: algoliaPrices?.[product.partNumber],
        }),
      }
    }

    return acc
  }, {})
}

export const getProductsForTYPAnalytics = (
  products: IProduct[],
  orderDetails: IOrderDetails,
  partNumberQuantities: Map<string, number>,
  algoliaPrices: PartNumberAlgoliaPrice
): ProductForAnalytics => {
  const orderItems = orderDetails.orderItem || []
  let formattedProducts = getProductsInCartForAnalytics(products, partNumberQuantities, algoliaPrices)

  for (const orderItemId in formattedProducts) {
    const currentFormattedProduct = formattedProducts[orderItemId]
    const orderItem = orderItems.find(i => i.orderItemId === orderItemId)

    if (orderItem) {
      formattedProducts[orderItemId] = {
        ...currentFormattedProduct,
        ...formatProductForTYP(orderItem),
      }
    }
  }
  return formattedProducts
}

export const getOrderTotalDiscount = (orderDetails: IOrderDetails): string => {
  return (
    orderDetails?.adjustment
      ?.filter(adj => adj?.usage === ADJUSTMENT_DISCOUNT)
      ?.reduce((prev, curr) => prev + parseFloat(curr.amount), 0)
      ?.toFixed(2) ?? '0.00'
  )
}

export const getOrderShippingDiscount = (orderDetails: IOrderDetails): string => {
  return (
    orderDetails?.adjustment
      ?.filter(adj => adj?.usage === SHIPPING_ADJUSTMENT_TEXT)
      ?.reduce((prev, curr) => prev + parseFloat(curr.amount), 0)
      ?.toFixed(2) ?? '0.00'
  )
}

export const getOrderState = (orderDetails: IOrderDetails): string => get(orderDetails, 'paymentInstruction[0].country')

export const getOrderZipCode = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].zipCode')

export const getOrderPaymentInstructionDescription = (order: IOrderSliceState): string => {
  const paymentType = get(order.orderDetails, 'paymentInstruction[0].piDescription', '').replace(/\s/g, '')

  if (paymentType === 'AmericanExpress') {
    return 'Amex'
  }
  return paymentType
}

export const getOrderPaymentType = (order: IOrderSliceState): string => {
  const paymentType = getOrderPaymentInstructionDescription(order)

  if (
    (paymentType === CHECKOUT_NAMES['APPLE_PAY'] && order.applePayInfo.flow === 'express') ||
    order?.paypalExpress?.isSelected
  ) {
    return `${paymentType}ExpressCheckout`
  }

  return paymentType
}
