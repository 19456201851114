import { IStoreManagerSSR } from './types'

export class StateManagerSSR<T> implements IStoreManagerSSR<T> {
  private key = ''

  constructor(key: string) {
    this.key = key
  }

  public getData = (locale: string): T | null => {
    return globalThis[this.key]?.[locale]
  }

  public setData = (data: T, locale: string): void => {
    // used globalThis to store data in memory because api routes are executd in separate workers
    globalThis[this.key] = data
      ? {
          ...(globalThis[this.key] ?? {}),
          [locale]: {
            ...(globalThis[this.key]?.[locale] ?? {}),
            ...data,
          },
        }
      : null
  }

  public purgeData = (): void => {
    globalThis[this.key] = null
  }
}
