import { AssociatedProduct, IProduct, IServerProduct } from '../types/product'

export const fromServerToClientProduct = (product: IServerProduct, type?: string): AssociatedProduct | IProduct => {
  const commonProps = {
    ...product,
    numberOfSKUs: product.sKUs?.length || 0,
  }
  switch (type) {
    case 'ProductBean':
      return {
        ...commonProps,
        items: product.items?.map(item => fromServerToClientProduct(item, item?.catalogEntryTypeCode)) || [],
        merchandisingAssociations:
          product.merchandisingAssociations
            ?.map(association => {
              return association.cluster?.map(cluster => {
                return fromServerToClientProduct(cluster, 'AssociatedProduct')
              })
            })
            .flat() ?? [],
      } as IProduct
    case 'ItemBean':
      return {
        ...commonProps,
      }
    case 'AssociatedProduct':
      return {
        ...commonProps,
        associationType: product.associationType,
      }
    default:
      return {
        ...commonProps,
        cluster:
          product.cluster?.map(clusterItem =>
            fromServerToClientProduct(clusterItem, clusterItem?.catalogEntryTypeCode)
          ) || [],
      }
  }
}
