import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/query/react'
import { IOrderSliceState } from './IOrderSliceState'
import { fetchOrderItemDetailsByIds } from '../../redux/sagas/workers/orderDetails'
import Log from '../../services/Log'
import {
  CalculateCartPayload,
  CheckoutPayload,
  PayerAuthResponse,
  PrescriptionDetailsPayload,
  PrescriptionFilePayload,
  PrescriptionPreviewPayload,
} from '../../types/checkout'
import { CartPayload, OneClickReorderPayload, ReorderData, ShippingChargesPayload } from '../../types/cart'
import {
  Cart,
  MiniCart,
  IOrderDetails,
  IPromoCode,
  IReorderInfo,
  OrderItem,
  PaymentInstruction,
  ShippingChargesWithoutPromotions,
  ShippingModes,
} from '../../types/order'
import { getCookieByName } from '../../utils/cookie'
import { ORDER_CONFIGS } from '../../configs/order'
import { isClOrderItem } from '../../utils/order'
import { isClAccessoriesProduct } from '../../utils/product'
import { COUNTRY_MAP } from '../../constants/common'
import { RootReducerState } from '../../redux/rootReducer'
import { IPayloadPromoCode } from '@typesApp/common'
import { localStorageUtil } from '@foundation/utils/storageUtil'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  tagTypes: ['Cart', 'Order', 'MiniCart'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getCart: build.query<
      {
        cart: Cart
        catentries?: IOrderSliceState['catentries']
        shippingInfo: IOrderSliceState['shipInfos']
        checkInventory: boolean
        fetchShippingInfo: boolean
        filterPrescriptionItemType?: 'cl' | 'rx'
        filterPrescriptionNeededItems?: boolean
      },
      CartPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const state = _queryApi.getState() as RootReducerState
          const currency = args?.currency || state.site?.currentSite?.defaultCurrencyID || ''
          const storeId = args?.storeId || state.site?.currentSite?.storeID || ''

          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self`,
            method: 'get',
            queryParams: args['queryParams'] || {},
            pathParams: { storeId },
            body: args.body || {},
          })

          const {
            checkInventory,
            fetchShippingInfo,
            cancelToken,
            widget,
            filterPrescriptionItemType,
            filterPrescriptionNeededItems,
          } = args
          let catentries: IOrderSliceState['catentries']
          let shippingInfo = undefined
          if (result) {
            const cart = result.data
            if (cart) {
              const orderItems = cart.orderItem
              if (orderItems && orderItems.length > 0) {
                let catentryIdList: string[] = []
                //get product info for all items
                orderItems.forEach((item: any) => {
                  catentryIdList.push(item.productId)
                })
                if (catentryIdList.length > 0) {
                  catentryIdList = [...new Set(catentryIdList)]
                  const contractId = args['contractId'] || ''
                  const paramsProduct: any = {
                    currency: currency,
                    contractId: contractId,
                    id: catentryIdList,
                  }
                  if (cancelToken) {
                    paramsProduct['cancelToken'] = cancelToken
                  }
                  if (widget) {
                    paramsProduct['widget'] = widget
                  }

                  try {
                    const contents = await fetchOrderItemDetailsByIds(paramsProduct)
                    if (contents) {
                      catentries = contents.reduce((acc, p: any) => {
                        acc[p.id] = p
                        return acc
                      }, {})
                    }
                  } catch (error: any) {
                    Log.error('Could not retrieve products: ' + error)
                    //Cannot retrieve catentry details; return order items as-is
                    catentries = undefined
                  }
                }
              }
            }
          }

          if (catentries) {
            return {
              data: {
                cart: result.data,
                catentries,
                checkInventory: checkInventory || false,
                shippingInfo,
                fetchShippingInfo: fetchShippingInfo || false,
                filterPrescriptionItemType,
                filterPrescriptionNeededItems: filterPrescriptionNeededItems || false,
              },
            }
          }
          return {
            data: {
              cart: result.data,
              checkInventory: checkInventory || false,
              shippingInfo,
              fetchShippingInfo: fetchShippingInfo || false,
              filterPrescriptionItemType,
              filterPrescriptionNeededItems: filterPrescriptionNeededItems || false,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
      providesTags: (result, error, arg) => {
        return error ? [] : [{ type: 'Cart', id: arg.storeId }]
      },
    }),
    getMiniCart: build.query<
    {
      miniCart: MiniCart
    },
    CartPayload
  >({
    async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
      try {
        const storeId = args?.storeId || ''
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/minicart`,
          method: 'get',
          queryParams: args['queryParams'] || {},
          pathParams: { storeId },
          body: args.body || {},
        })
  
        return {
          data: {
            miniCart: result.data, // Include miniCart explicitly
          },
        }
      } catch (error) {
        return { error: error as FetchBaseQueryError }
      }
    },
    providesTags: (result, error, arg) => {
      return error ? [] : [{ type: 'MiniCart', id: arg.storeId }]
    },
  }),
  
    updateOrderItem: build.mutation<
      {
        orderItemId: string
      },
      CartPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/update_order_item`,
          method: 'put',
          body: {
            userAgent,
            forterToken,
            x_calculateOrder: ORDER_CONFIGS.calculateOrder,
            x_calculationUsage: ORDER_CONFIGS.calculationUsage,
            x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
            ...args.body,
          },
        })

        if (result?.data) {
          return { data: { orderItemId: result.data.orderItem[0].orderItemId } }
        }
        return { error: result.error }
      },
    }),
    removeOrderItem: build.mutation<
      {
        orderId: string
      },
      {
        orderItem: OrderItem
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const state = _queryApi.getState() as RootReducerState
        const { orderItem } = args
        const { orderItemId, roxableServices } = orderItem
        const payload: Record<string, string> = {}
        const isClAccessoriesOrder = isClAccessoriesProduct(orderItem)

        if (roxableServices && roxableServices.length > 0) {
          payload.orderItemId_1 = roxableServices[0]?.orderItemId
          payload.orderItemId_2 = orderItemId
          payload.orderItemId_3 = roxableServices[1]?.orderItemId
          payload.orderItemId_4 = roxableServices[2]?.orderItemId
          payload.orderItemId_5 = roxableServices[3]?.orderItemId
        } else if (isClAccessoriesOrder || isClOrderItem(orderItem.orderItemExtendAttribute)) {
          if (!!orderItem.groupedOrderItemsId) {
            orderItem.groupedOrderItemsId.map((orderId, index) => {
              const position = `orderItemId_${index.toString()}`
              payload[position] = orderId
            })
          } else {
            payload.orderItemId = orderItemId
          }
        } else {
          payload.orderItemId = orderItemId
        }

        const storeId = state.site?.currentSite?.storeID || ''
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/delete_order_item`,
          method: 'put',
          body: {
            userAgent,
            forterToken,
            x_calculateOrder: ORDER_CONFIGS.calculateOrder,
            x_calculationUsage: ORDER_CONFIGS.calculationUsage,
            ...payload,
          },
        })

        if (result?.data) {
          return { data: { orderId: result.data?.orderId[0] } }
        }
        return { error: result.error }
      },
    }),
    // TODO: remove and consolidate calls into RTK
    deleteOrderItem: build.query<
      {
        orderId: string
      },
      CartPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/delete_order_item`,
          method: 'put',
          body: {
            userAgent,
            forterToken,
            ...args.body,
            x_calculateOrder: ORDER_CONFIGS.calculateOrder,
            x_calculationUsage: ORDER_CONFIGS.calculationUsage,
          },
        })

        if (result?.data) {
          return { data: { orderId: result.data?.orderId[0] } }
        }
        return { error: result.error }
      },
    }),
    deleteCLOrderItems: build.mutation<
      { orderId: string },
      { storeId: string; leftItemId?: string; rightItemId?: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, leftItemId, rightItemId } = args
        const payload: Record<string, string> = {}

        if (!!leftItemId) {
          payload['orderItemId_0'] = leftItemId
        }
        if (!!rightItemId) {
          payload['orderItemId_1'] = rightItemId
        }

        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/delete_order_item`,
          method: 'put',
          body: {
            ...payload,
          },
        })

        if (result?.data) {
          return { data: { orderId: result.data?.orderId[0] } }
        }
        return { error: result.error }
      },
    }),
    getShippingInfo: build.query<ShippingModes, { orderId: string }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const state = _queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID || ''
        const { orderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/ShipModes/GetShipModes?orderId=${orderId}`,
          method: 'get',
          pathParams: { storeId },
          body: {
            userAgent,
            forterToken,
          },
        })
        return result?.data ? { data: result.data } : { error: result.error as FetchBaseQueryError }
      },
    }),
    /* FSA (Forward Sortation Area) is a zip code based value
      and each FSA has its own lead time based on shipping methods.
      This ideally needs to be handled by BE but due to time constraints,
      this is being handled from FE. The JSON is called using HTTP call
      to future-proof this so that this can be easily changed to BE call. */
    getShippingFsaLeadTimeMap: build.query({
      async queryFn() {
        const [caData, auData, nzData] = await Promise.all([
          fetch('/json/fsaLeadTimeMapCA.json'),
          fetch('/json/fsaLeadTimeMapAU.json'),
          fetch('/json/fsaLeadTimeMapNZ.json'),
        ])
        const fsaMap = {}
        fsaMap[COUNTRY_MAP.CANADA] = await caData.json()
        fsaMap[COUNTRY_MAP.AUSTRALIA] = await auData.json()
        fsaMap[COUNTRY_MAP.NEW_ZEALAND] = await nzData.json()
        return fsaMap[COUNTRY_MAP.CANADA] && fsaMap[COUNTRY_MAP.AUSTRALIA] && fsaMap[COUNTRY_MAP.NEW_ZEALAND]
          ? { data: fsaMap }
          : { error: 'FSA data files missing!' }
      },
    }),
    updateOrderShippingInfo: build.mutation<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/shipping_info`,
          method: 'PUT',
          body: {
            userAgent,
            forterToken,
            ...args.body,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    addPaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const webId = localStorageUtil.get('ON_BEHALF_FORTER_WEB_ID') || localStorageUtil.get('FORTER_WEB_ID') || ''
        try {
          const state = _queryApi.getState() as RootReducerState
          const storeId = state.site.currentSite?.storeID || ''
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'POST',
            body: {
              userAgent,
              forterToken,
              webId,
              ...args.body,
            },
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    deleteAllPaymentInstructions: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const state = _queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID || ''
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/payment_instruction`,
          method: 'DELETE',
          body: {
            userAgent,
            forterToken,
            ...args.body,
          },
        })
        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    updatePaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const state = _queryApi.getState() as RootReducerState
          const storeId = state.site.currentSite?.storeID || ''
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'PUT',
            body: {
              userAgent,
              forterToken,
              ...args.body,
            },
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    calculateCart: build.query<
      {
        orderId: string[]
        viewTaskName: string
      },
      CalculateCartPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const state = _queryApi.getState() as RootReducerState
          const storeId = state?.site?.currentSite?.storeID ?? state?.site?.currentSite?.storeId ?? ''
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/calculate`,
            method: 'POST',
            queryParams: {
              updatePrices: 1,
            },
            body: {
              userAgent,
              forterToken,
              x_calculateOrder: 1,
              calculationUsageId: [-1, -2, -3, -4, -5, -6, -7],
              orderId: args?.orderId ? args.orderId : '.',
            },
          })
          return {
            data: result.data,
          }
        } catch (error) {
          return { error }
        }
      },
    }),
    executePreCheckout: build.mutation<
      {
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, body } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/precheckout`,
          method: 'PUT',
          body: body || {},
        })

        return result?.data ? { data: { orderId: body?.orderId || '' } } : { error: result.error }
      },
    }),
    finalizeOrderWithCybersource: build.mutation<
      {
        orderComplete: boolean
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, body } = args
        await fetchWithBQ({
          url: `store/${storeId}/cart/@self/precheckout`,
          method: 'PUT',
          body: body || {},
        })
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/checkout`,
          method: 'POST',
          body: {
            userAgent,
            forterToken,
            ...body,
          },
        })
        return result?.data ? { data: { orderComplete: true, orderId: body?.orderId || '' } } : { error: result.error }
      },
      invalidatesTags: (result, _error, arg) => {
        return result ? [{ type: 'Order', id: arg.orderId }] : []
      },
    }),
    findOrderdById: build.query<
      {
        orderData: IOrderDetails
      },
      { orderId: string } & Partial<CheckoutPayload>
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const state = _queryApi.getState() as RootReducerState
        const currency = args?.currency || state.site?.currentSite?.defaultCurrencyID || ''
        const storeId = args?.storeId || state.site?.currentSite?.storeID || ''
        const { orderId, cancelToken, widget } = args

        const result = await fetchWithBQ({
          url: `store/${storeId}/order/${orderId}`,
          method: 'GET',
          body: {
            ...args.body,
            profileName: 'LX_findItemByIds_Details',
          },
        })

        let catentries: IOrderSliceState['catentries']
        if (result) {
          const cart = result.data
          if (cart) {
            const orderItems = cart.orderItem
            if (orderItems && orderItems.length > 0) {
              let catentryIdList: string[] = []
              //get product info for all items
              orderItems.forEach((item: any) => {
                catentryIdList.push(item.productId)
              })
              if (catentryIdList.length > 0) {
                catentryIdList = [...new Set(catentryIdList)]
                const contractId = args['contractId'] || ''
                const paramsProduct: any = {
                  currency: currency,
                  contractId: contractId,
                  id: catentryIdList,
                }
                if (cancelToken) {
                  paramsProduct['cancelToken'] = cancelToken
                }
                if (widget) {
                  paramsProduct['widget'] = widget
                }

                try {
                  const contents = await fetchOrderItemDetailsByIds(paramsProduct)
                  if (contents) {
                    catentries = contents.reduce((acc, p: any) => {
                      acc[p.id] = p
                      return acc
                    }, {})
                  }
                } catch (error: any) {
                  Log.error('Could not retrieve products: ' + error)
                  //Cannot retrieve catentry details; return order items as-is
                  catentries = undefined
                }
              }
            }
          }
        }

        return result?.data
          ? {
              data: {
                orderData: result.data,
                ...(catentries && { catentries }),
              },
            }
          : { error: result.error }
      },
      providesTags: (result, error, arg) => {
        return error ? [] : [{ type: 'Order', id: arg.orderId }]
      },
    }),
    setupPayerAuthentication: build.query<PayerAuthResponse, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/payerAuthentication/paSetup/@self`,
          method: 'GET',
          body: {
            userAgent,
            forterToken,
            ...args.body,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    paypalExpressCheckStatus: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/paypal/checkStatus/@self`,
          method: 'GET',
          body: {
            userAgent,
            forterToken,
            ...args.body,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/storeRXFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile/multiple`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadPrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/contactLens/prescriptionDetails`,
          method: 'POST',
          body: {
            ...args.details,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails`,
          method: 'POST',
          body: {
            ...args.details,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails/multiple`,
          method: 'POST',
          body: {
            ...args.details,
          },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    getPrescriptionFilePreview: build.query<any, PrescriptionPreviewPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, rxFileStorageId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/rxFileStorageId/${rxFileStorageId}`,
          method: 'GET',
          body: {
            ...args,
          },
        })
        return result?.data
          ? {
              data: { content: result.data, headers: result.headers },
            }
          : { error: result.error }
      },
    }),

    getShippingPrice: build.query<{ shipList: ShippingChargesWithoutPromotions[] }, ShippingChargesPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId } = args
        try {
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/orderId/${orderId}/shipCharges`,
            method: 'GET',
          })
          return result?.data && result.data.ships.shipList.length
            ? { data: result.data.ships }
            : { error: result.error }
        } catch (e) {
          Log.error('Could not retrieve shipping price')
          return {
            error: e,
          }
        }
      },
    }),

    copyOrder: build.mutation<void, ReorderData>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const state = _queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID || ''

        const payload = {
          ...args,
          copyOrderItemId: args.copyOrderItemId || '*', // default copy all items
          fromOrderId_0: args.fromOrderId_0 || args.fromOrderId,
        } as ReorderData

        const result = await fetchWithBQ({
          url: `/store/${storeId}/cart/copy_order`,
          method: 'POST',
          body: payload,
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),

    oneClickReorder: build.mutation<IReorderInfo, OneClickReorderPayload>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID || ''

        const payload = {
          orderId: args.orderId,
        }

        const result = await fetchWithBQ({
          url: `store/${storeId}/reorder/oneClick`,
          method: 'POST',
          body: payload,
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),

    getOneClickReorder: build.query<IReorderInfo, OneClickReorderPayload>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID || ''

        const result = await fetchWithBQ({
          url: `store/${storeId}/reorder/oneClick`,
          queryParams: args.isOldOrderId ? { fromOrderId: args.orderId } : { orderId: args.orderId },
          method: 'GET',
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),

    applyPromotioncode: build.query<IPromoCode, { storeId: string; payload: IPayloadPromoCode }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/assigned_promotion_code`,
          method: 'POST',
          body: {
            ...payload,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),

    removePromotioncode: build.query<IPromoCode, { storeId: string; promoCode: string; payload: IPayloadPromoCode }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, promoCode, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/assigned_promotion_code/${promoCode}`,
          method: 'DELETE',
          body: {
            ...payload,
          },
          pathParams: { storeId, promoCode },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),

    getAssociatedPromotion: build.query<
      any,
      {
        storeId: string
        payload: {
          catentryId: string
          currency: string
        }
      }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/associated_promotion`,
          method: 'GET',
          queryParams: {
            qProductId: payload.catentryId,
            q: 'byProduct',
            responseFormat: 'json',
            currency: payload.currency,
            profileName: 'IBM_catalogEntryDetailed',
            qIncludeCategoryPromotions: 'true',
            qIncludeChildItems: 'true',
            qIncludeChildCategories: 'true',
            qIncludeParentProduct: 'true',
            qIncludeParentCategories: 'true',
            qIncludePromotionCode: 'true',
            qStoreId: storeId,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
  }),
})

export const {
  useGetCartQuery,
  useLazyGetCartQuery,
  useGetMiniCartQuery,
  useLazyGetMiniCartQuery,
  useRemoveOrderItemMutation,
  useDeleteCLOrderItemsMutation,
  useUpdateOrderItemMutation,
  useLazyFindOrderdByIdQuery,
  useFindOrderdByIdQuery,
  useGetShippingFsaLeadTimeMapQuery,
  useGetShippingInfoQuery,
  useLazyGetShippingInfoQuery,
  useCopyOrderMutation,
  useFinalizeOrderWithCybersourceMutation,
  useExecutePreCheckoutMutation,
  useOneClickReorderMutation,
  useLazyGetOneClickReorderQuery,
  useLazyGetAssociatedPromotionQuery,
  useLazyCalculateCartQuery,
  usePaypalExpressCheckStatusQuery,
  useLazyPaypalExpressCheckStatusQuery,
} = orderApi
