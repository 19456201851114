//Standard libraries
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios'
import { FOR_USER_ID, LANGID, SKIP_WC_TOKEN_HEADER, WC_PREVIEW_TOKEN } from '../constants/common'
import { INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from 'http-status-codes'
import { localStorageUtil, storageSessionHandler, storageStoreIdHandler } from '../utils/storageUtil'
import * as userAction from '../../redux/actions/user'
//Custom libraries
import { CommerceEnvironment, STORE_ID_QUERY_PLACEHOLDER } from '../../constants/common'
import { PERSONALIZATION_ID } from '../constants/user'
//Redux
import { WATCH_AXIOS_ERROR_ACTION } from '../../redux/actions/error'
//Foundation libraries
import { axiosHeaderIgnoredServices } from '../configs/axiosHeaderIgnoredService'
import config from '../../configs'
import i18n from 'i18next'
import { parse as losslessParse } from 'lossless-json'
import { numberParserRequiredServices } from '../configs/numberParserRequiredService'
import { site } from '../constants/site'
import { ADD_FRAME_ROX_ORDER_DETAILS_ACTION } from '../../redux/actions/orderDetails'
import { sendServerErrorEvent } from '../analytics/tealium/lib'
import { SiteInfoService } from '../hooks/useSite/SiteInfoService'
import { withCredentialsEnabled } from '@utils/helpers'

interface ErrorEntry {
  id: string
  timestamp: number
}

const isServiceInList = (request: AxiosRequestConfig, serviceList: string[]) => {
  const url = request.url === undefined ? '' : request.url
  if (url.length > 0) {
    const storePath = `${site.transactionContextUrl}/store/`
    const path = url.split(storePath).pop()
    if (path && path.length > 0) {
      const serviceName = path.split('/')[1]
      return serviceList.indexOf(serviceName) > -1
    }
  }
  return false
}

const isNumberParserRequiredService = (request: AxiosRequestConfig) => {
  return isServiceInList(request, numberParserRequiredServices)
}

const dispatchObject = {
  _dispatch: null,
  set dispatch(dispatch: any) {
    this._dispatch = dispatch
  },
  get dispatch(): any {
    return this._dispatch
  },
}

const processForUserParameter = (params: URLSearchParams) => {
  // Log.debug('PROCESS FOR USER PARAMETER')
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser && currentUser.forUserId) {
    params.set(FOR_USER_ID, currentUser.forUserId)
  }
}

const processTransactionHeader = (header: any) => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser) {
    if (!header['WCTrustedToken']) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken']) {
      header['WCToken'] = currentUser.WCToken
    }
    // TODO: uncomment it when lux api allows make a call with WCPersonalization header!!!
    if (!header['WCPersonalization']) {
      header['WCPersonalization'] = currentUser.personalizationID
    }
  }
  // TODO: uncomment it when lux api allows make a call with WCPersonalization header!!!
  if (!header['WCPersonalization']) {
    const personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
    if (personalizationID !== null) {
      header['WCPersonalization'] = personalizationID
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const processSearchHeader = (header: any) => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser) {
    if (!header['WCTrustedToken']) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken']) {
      header['WCToken'] = currentUser.WCToken
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const transformNumberResponse = function (data) {
  if (typeof data === 'string') {
    data = losslessParse(data, value => {
      // @ts-ignore
      if (value && value.isLosslessNumber) {
        return value.toString()
      } else {
        return value
      }
    })
  }
  return data
}

const useSnackbarHandleError = (error: AxiosError) => {
  if (error.config) {
    const { skipErrorSnackbar } = error.config as any
    if (
      skipErrorSnackbar === true &&
      error.response &&
      error.response.status < INTERNAL_SERVER_ERROR
      // status 500 and above will be handled by snackbar
    ) {
      return false
    }
  }
  return !(error.isAxiosError && error.response && error.response.status === NOT_FOUND)
}

/*
  For clear local session for unauthorized user
*/
const updateLocalSession = dispatch => {
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  let payload = {
    ...payloadBase,
  }
  dispatch(userAction.LOGOUT_SUCCESS_ACTION(payload))
}

const uniqueErrorSet = new Set<ErrorEntry>()

const initAxios = (dispatch: any) => {
  dispatchObject.dispatch = dispatch

  // In order to correctly use the Akamai Bot Protection feature,
  // all fetch/XHR calls to BE must send cookies along with the request.
  Axios.defaults.withCredentials = withCredentialsEnabled()

  Axios.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      if (
        request.url?.startsWith(site.transactionContextUrl) &&
        !isServiceInList(request, axiosHeaderIgnoredServices)
      ) {
        const header = request.headers
        if (!request[SKIP_WC_TOKEN_HEADER]) {
          processTransactionHeader(header)
        }
      }
      if (request.url?.startsWith(site.searchContextUrl)) {
        const header = request.headers
        processSearchHeader(header)
      }
      return request
    },
    function (error: any) {
      return Promise.reject(error)
    }
  )
  Axios.interceptors.response.use(
    function (response: AxiosResponse) {
      return response
    },
    function (error) {
      if (error?.response?.data?.['lenseItemId']) {
        dispatch(ADD_FRAME_ROX_ORDER_DETAILS_ACTION(error?.response?.data))
        // dispatch(ADD_FRAME_ROX_ERROR_ACTION(error?.response?.data))
      }
      if (error?.response?.status === UNAUTHORIZED) {
        updateLocalSession(dispatch)
      }
      const errorEntry = {
        id: getErrorIdentifier(error),
        timestamp: Date.now(),
      }
      if (error?.response?.status !== NOT_FOUND && !isErrorInSet(errorEntry)) {
        sendServerErrorEvent(error)
        uniqueErrorSet.add(errorEntry) // Track the error with timestamp
        setTimeout(() => clearExpiredErrors(), 1000)
      }
      if (useSnackbarHandleError(error)) {
        dispatch(WATCH_AXIOS_ERROR_ACTION(error))
      }
      return Promise.reject(error)
    }
  )
}

// Utility function to generate a unique identifier for the error
const getErrorIdentifier = (error: AxiosError): string => `${error.response?.status}_${error.message}`

// Check if the error is already in the set based on identifier
const isErrorInSet = (errorEntry: ErrorEntry): boolean => {
  for (const entry of uniqueErrorSet) {
    if (entry.id === errorEntry.id) return true
  }
  return false
}

// Clear expired errors from the set based on timestamp
const clearExpiredErrors = () => {
  for (const entry of uniqueErrorSet) {
    uniqueErrorSet.delete(entry)
  }
}

const executeRequest = async <T = any>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  request.timeout = config.apiCalltimeout
  const params: URLSearchParams = request?.params
  const siteInfo = SiteInfoService.getSiteInfo().getSiteValue()
  if (request?.url?.includes(STORE_ID_QUERY_PLACEHOLDER)) {
    request.url = request.url.replace(STORE_ID_QUERY_PLACEHOLDER, siteInfo?.storeID || '')
  }
  processForUserParameter(params)
  //verify active storeId in localStorage.
  storageStoreIdHandler.verifyActiveStoreId()
  if (params && !params.has(LANGID)) {
    // add language Id
    const lng = (i18n as any).logger?.options?.lng?.split('-').join('_')
    const langId = CommerceEnvironment.reverseLanguageMap[lng]
    params.set(LANGID, langId)
  }

  if (isNumberParserRequiredService(request)) {
    request.transformResponse = [transformNumberResponse]
  }

  return Axios(request)
}

export { initAxios, executeRequest }
