import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  CustomerSegmentPayload,
  CustomerSegmentsResponse,
  CustomerSegmentBasePayload,
  EspotCampaignPayload,
  EspotDataResponse,
  PersonalizationIdResponse,
} from '@typesApp/customerSegment'
import { RootReducerState } from '@redux/rootReducer'

export const customerSegmentApi = createApi({
  reducerPath: 'customerSegmentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  tagTypes: ['CustomerSegment', 'PersonalizationID', 'EspotData'],
  endpoints: builder => ({
    getPersonalizationId: builder.query<PersonalizationIdResponse, CustomerSegmentBasePayload>({
      // NOTE: when doing development on localhost, `Set-Cookie` headers returned by the server are ignored by browsers
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `/store/${storeId}/usercontext/personalizationId`,
          method: 'GET',
        })
        if (result?.data) {
          return { data: result.data }
        }
        return { error: result.error }
      },
    }),
    getCampaignAccess: builder.query<EspotDataResponse, EspotCampaignPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, campaignName, personalizationId } = args
        const state = queryApi.getState() as RootReducerState
        const WCToken = state.user.WCToken
        const WCTrustedToken = state.user.WCTrustedToken

        const result = await fetchWithBQ({
          url: `/store/${storeId}/espot/${campaignName}`,
          method: 'GET',
          headers: { WCPersonalization: personalizationId, WCToken, WCTrustedToken },
        })
        if (result?.data) {
          return { data: result.data }
        }
        return { error: result.error }
      },
    }),
    getCustomerSegments: builder.query<CustomerSegmentsResponse, CustomerSegmentPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, personalizationId } = args
        const state = queryApi.getState() as RootReducerState
        const WCToken = state.user.WCToken
        const WCTrustedToken = state.user.WCTrustedToken
        const result = await fetchWithBQ({
          url: `/store/${storeId}/customersegments`,
          method: 'GET',
          headers: { WCPersonalization: personalizationId, WCToken, WCTrustedToken },
        })
        if (result?.data) {
          return { data: result.data }
        }
        return { error: result.error }
      },
      providesTags: ['CustomerSegment'],
    }),
  }),
})

export const {
  useGetCustomerSegmentsQuery,
  useGetPersonalizationIdQuery,
  useLazyGetPersonalizationIdQuery,
  useGetCampaignAccessQuery,
  useLazyGetCampaignAccessQuery,
} = customerSegmentApi
